import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Button, Heading, useMultiStyleConfig, VStack } from '@chakra-ui/react';
import DOMPurify from 'isomorphic-dompurify';
import React from 'react';
import { cmsRichTextRules } from '../../constants/sanitize';
import { Link } from '../../types';

interface TextContentProps {
  alternateContentMediaItemLink?: Link | null;
  alternateContentMediaItemText?: string;
  alternateContentMediaItemTitle: string;
  alternateContentMediaTitle: string;
  isOddRow: boolean;
}

const TextContent = ({
  alternateContentMediaItemLink,
  alternateContentMediaItemText,
  alternateContentMediaItemTitle,
  alternateContentMediaTitle,
  isOddRow,
}: TextContentProps) => {
  const theme = useMultiStyleConfig('AlternativeContentMedia', {
    baseStyles: true,
  });

  return (
    <VStack
      flex="1"
      px={{ base: 0, lg: 74 }}
      gap={3}
      alignItems={{ base: 'flex-start', lg: isOddRow ? 'flex-start' : 'flex-end' }}
      justifyContent="center"
    >
      {alternateContentMediaItemTitle && (
        <Heading
          as={alternateContentMediaTitle ? 'h3' : 'h2'}
          textAlign="left"
          alignSelf="flex-start"
          size={{ base: 'xl', '2xl': '2xl' }}
        >
          {alternateContentMediaItemTitle}
        </Heading>
      )}
      {alternateContentMediaItemText && (
        <Box
          className="RichText"
          textAlign="left"
          alignSelf="flex-start"
          mb="2"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(alternateContentMediaItemText, cmsRichTextRules),
          }}
        />
      )}
      {alternateContentMediaItemLink && (
        <Button
          as="a"
          mt={0}
          href={alternateContentMediaItemLink.url}
          rightIcon={<ArrowForwardIcon />}
          size="md"
          textAlign="left"
          alignSelf="flex-start"
          target={alternateContentMediaItemLink.isNewWindow ? '_blank' : '_self'}
          rel={
            alternateContentMediaItemLink.isNewWindow
              ? 'noopener' // prevents tabnabbing - security measure
              : ''
          }
          sx={theme.ctaButton}
        >
          {alternateContentMediaItemLink.title}
        </Button>
      )}
    </VStack>
  );
};

export default TextContent;
