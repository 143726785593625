'use client';

import { Box, Heading, VStack } from '@chakra-ui/react';
import React from 'react';
import { IAlternateContentMedia } from '../../types';
import Module from '../Module';
import AlternateContentMediaItem from './AlternateContentMediaItem';

const AlternateContentMedia = ({
  alternateContentMediaTitle,
  alternateContentMediaItems,
}: IAlternateContentMedia) => {
  return (
    <Module className="AlternateContentMedia">
      <Box>
        {alternateContentMediaTitle && (
          <Heading as="h2" size={{ base: '2xl', lg: '3xl' }}>
            {alternateContentMediaTitle}
          </Heading>
        )}
        <VStack
          gap={{
            base: '1.125rem',
            sm: '1.6rem',
            md: '2rem',
            lg: '6.25rem',
            xl: '7rem',
          }}
        >
          {alternateContentMediaItems.map((contentItem, index) => (
            <AlternateContentMediaItem
              data={contentItem}
              alternateContentMediaTitle={alternateContentMediaTitle}
              index={index}
              key={`${contentItem.alternateContentMediaItemTitle}-${index}`}
            />
          ))}
        </VStack>
      </Box>
    </Module>
  );
};

AlternateContentMedia.displayName = 'AlternateContentMedia';

export default AlternateContentMedia;
