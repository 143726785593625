import { Container, useTheme } from '@chakra-ui/react';
import React from 'react';

const StandardContainer = (props: any) => {
  const theme = useTheme();
  const { children, ...otherProps } = props;
  const innerMaxWidth = { base: '100%', xl: theme.breakpoints.xl };
  return (
    <Container maxWidth={innerMaxWidth} {...otherProps}>
      {children}
    </Container>
  );
};

export default StandardContainer;
