import { Flex, SystemStyleObject } from '@chakra-ui/react';
import React from 'react';
import { useNewsletterContext } from '..';

type Props = {
  children: React.ReactNode;
};

const NewsletterSubscriptionLayout = ({ children }: Props) => {
  return <Flex flexDir={{ base: 'column', md: 'row' }}>{children}</Flex>;
};

const Right = ({ children }: Props) => {
  const hasImage = !!useNewsletterContext().image;

  const stylesForNoImage: SystemStyleObject = {
    borderRadius: 10,
    pt: {
      base: 6,
      md: 9,
    },
    pb: {
      base: 4,
      md: 5,
    },
    width: '100%',
  };

  const stylesForImage: SystemStyleObject = {
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: {
      base: 10,
      md: 0,
    },
    borderTopRightRadius: {
      base: 0,
      md: 10,
    },
    py: {
      base: 3,
      md: 10,
    },
  };

  return (
    <Flex
      className="NewsletterSubscriptionLayout"
      flex={{ base: '1 0 50%', lg: '1 0 33%' }}
      px={{
        base: 4,
        md: 10,
      }}
      flexDir="column"
      alignSelf="stretch"
      justifyContent="center"
      border="1px solid var(--chakra-colors-chakra-border-color)"
      sx={hasImage ? stylesForImage : stylesForNoImage}
    >
      {children}
    </Flex>
  );
};

NewsletterSubscriptionLayout.Right = Right;

export default NewsletterSubscriptionLayout;
