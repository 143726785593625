import { Search as SearchComponent } from 'inspiretec-booking-journey-ui';
import { BookingJourneySearchFormConfig } from 'inspiretec-booking-journey-ui/api/Config/useConfig';
import React from 'react';
import ConditionalWrapper from '../ConditionalWrapper';
import Module from '../Module';

export interface SearchProps extends BookingJourneySearchFormConfig {
  isModule: boolean;
}

const Search = ({ isModule, ...props }: SearchProps) => {
  return (
    <ConditionalWrapper
      condition={isModule}
      wrapper={(children) => <Module className="Search">{children}</Module>}
    >
      <SearchComponent {...props} />
    </ConditionalWrapper>
  );
};

Search.displayName = 'Search';

export default Search;
