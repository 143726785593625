import axios, { AxiosError, AxiosResponse } from 'axios';
import { UseMutateAsyncFunction, useMutation, UseMutationResult } from 'react-query';
import { getContentApi } from '../../utils/api';

interface IUseNewsletterSignupOptions {
  emailAddress: string;
  recaptchaToken: string;
}

type MutationFunctionSignature = (signupData: IUseNewsletterSignupOptions) => void;

interface CustomReturnParams {
  statusCode: number;
}

type UseNewsletterSignupReturnType = Omit<UseMutationResult, 'mutate'> & CustomReturnParams;

type IUseNewsletterSignupReturn = [MutationFunctionSignature, UseNewsletterSignupReturnType];

type AsyncMutationType = UseMutateAsyncFunction<
  AxiosResponse<any, any | null>,
  unknown,
  IUseNewsletterSignupOptions | unknown,
  unknown
>;

const useNewsletterSignup = (): IUseNewsletterSignupReturn => {
  const { mutate, ...rest } = useMutation({
    mutationFn: async (signupData: IUseNewsletterSignupOptions) => {
      if (!signupData?.emailAddress || !signupData?.recaptchaToken) return null;
      const url = `${getContentApi()}/Newsletter/Subscribe`;
      return await axios.post(url, {
        ...signupData,
      });
    },
  });
  const error = rest.error as AxiosError;
  const statusCode = error?.response?.status ?? rest.data?.status ?? 0;
  return [mutate, { ...rest, statusCode, mutateAsync: rest.mutateAsync as AsyncMutationType }];
};

export default useNewsletterSignup;
