import React, { ReactNode } from 'react';

type ConditionalWrapper = {
  children: ReactNode;
  condition: boolean;
  wrapper: (children: React.ReactNode) => any;
};

const ConditionalWrapper = ({ children, condition, wrapper }: ConditionalWrapper) => {
  if (condition) {
    return wrapper(children);
  }

  return children;
};

export default ConditionalWrapper;
