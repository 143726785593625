import { TriangleDownIcon } from '@chakra-ui/icons';
import { Box, Center, IconButton, useMultiStyleConfig } from '@chakra-ui/react';
import React from 'react';

const PlayButton = () => {
  const theme = useMultiStyleConfig('Video', { baseStyles: true });
  return (
    <Center outline={0} aria-label="Play video button">
      <IconButton
        tabIndex={-1} // focus on the overall player, rather than 2 x tab for player + button
        aria-label="" // required prop but handled above
        zIndex={2}
        data-testid="play-btn"
        icon={
          <Box transform="rotate(-90deg)" ml={1}>
            <TriangleDownIcon color="gray.700" w="35px" h="35px" sx={theme.iconTriangle} />
          </Box>
        }
        w="92px"
        h="92px"
        borderRadius="full"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={theme.iconBack}
      />
    </Center>
  );
};

export default PlayButton;
