import {
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import React from 'react';
import { ContentPlaceholder as IContentPlaceholder } from '../../types';
import ContentPlaceholder from '../ContentPlaceholder';
import Module, { ModuleType } from '../Module';

interface tabbedContentBlockItems {
  tabbedContentTitle: string;
  tabbedContentPlaceholder: IContentPlaceholder[];
}

export interface TabbedContentBlockProps {
  tabbedContentBlockTitle: string;
  tabbedContentBlockItems: tabbedContentBlockItems[];
}

const TabbedContentBlock = ({
  tabbedContentBlockTitle,
  tabbedContentBlockItems,
}: TabbedContentBlockProps) => {
  const theme = useMultiStyleConfig('TabbedContentBlock', { baseStyles: true });

  return (
    <Module className="TabbedContentBlock" type={ModuleType.CONTENT_WIDTH}>
      <Heading as="h2" size="3xl" mb={12} sx={theme.blockHeading}>
        {tabbedContentBlockTitle}
      </Heading>
      <Tabs position="relative" variant="unstyled" sx={theme.tabs}>
        <TabList overflowY="auto" sx={theme.list}>
          {tabbedContentBlockItems.map((item) => (
            <Tab key={item.tabbedContentTitle} sx={theme.button}>
              {item.tabbedContentTitle}
            </Tab>
          ))}
        </TabList>
        {/* Known chakra bug: indicator is controlled by chakra transitions but it doesn't match the width of the overflow above with scroll */}
        {/* Most effective solution is to hide this styling on smaller devices and apply underline to font at smaller breakpoints on selected tab */}
        {/* https://github.com/chakra-ui/chakra-ui/issues/2040 */}
        <TabIndicator display={{ base: 'none', md: 'block' }} sx={theme.indicator} />
        <TabPanels mt={12} sx={theme.panels}>
          {tabbedContentBlockItems.map((item) => (
            <TabPanel p={0} key={item.tabbedContentTitle} sx={theme.panel}>
              {item.tabbedContentPlaceholder && (
                <ContentPlaceholder isModule={false} components={item.tabbedContentPlaceholder} />
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Module>
  );
};

TabbedContentBlock.displayName = 'TabbedContentBlock';

export default TabbedContentBlock;
