import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import DOMPurify from 'isomorphic-dompurify';
import React from 'react';
import { cmsRichTextRules } from '../../constants/sanitize';
import ConditionalWrapper from '../ConditionalWrapper';
import Module, { ModuleType } from '../Module';
export interface ContentBlockProps {
  contentBlockText: string;
  isModule?: boolean;
}

const ContentBlock = ({ contentBlockText, isModule = true }: ContentBlockProps) => {
  const theme = useMultiStyleConfig('ContentBlock', { baseStyles: true });
  return (
    <ConditionalWrapper
      condition={isModule}
      wrapper={(children) => (
        <Module className="ContentBlock" type={ModuleType.CONTENT_WIDTH} sx={theme.contentBlock}>
          {children}
        </Module>
      )}
    >
      <Box
        className="RichText"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contentBlockText, cmsRichTextRules) }}
      />
    </ConditionalWrapper>
  );
};

ContentBlock.displayName = 'ContentBlock';

export default ContentBlock;
