import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Button, Heading, Text, useMultiStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { CarouselBaseItem, IImageCtaItem } from '../../types';
import Picture from '../Picture';

interface ImageCtaCarouselItem extends CarouselBaseItem {
  item: IImageCtaItem;
}

const ImageCtaCarouselItem = ({
  item,
  isFullWidth,
  isActiveSlide,
  index,
  variant,
}: ImageCtaCarouselItem) => {
  const carouselBaseTheme = useMultiStyleConfig('CarouselBase', {
    baseStyles: true,
    variant,
  });
  return (
    <Box
      w={{ base: '100%', lg: '100%' }}
      ml={{ base: '2px', sm: '10px', md: '10px', lg: 0 }}
      position="relative"
      className="CarouselItemContainer"
    >
      <Picture
        options={{
          image: item.imageCtaItemImage,
          eager: index === 0,
          sizes: [
            [5 / 4, '708px'],
            [5 / 4, '708px'],
            [16 / 9, 100],
            [16 / 9, 100],
            [isFullWidth ? 2.37 : 16 / 9, 100],
          ],
        }}
      />
      {(item.imageCtaItemTitle || item.imageCtaItemText || item.imageCtaItemLink) && (
        <Box
          position={{ base: 'static', md: 'absolute' }}
          className="DescriptionTextbox"
          display={{ base: 'flex', md: 'block' }}
          sx={{ flexDirection: 'column' }}
          bottom={{ base: '0px', md: '20px', lg: '30px' }}
          left={{
            base: '0px',
            md: '20px',
            lg: '30px',
            xl: isFullWidth
              ? 'calc((100vw - (1440px - 100px - 2rem)) / 2)' // Align left to max width rather than image
              : '93px',
          }}
          bgColor="white"
          borderRadius={{ base: '0px', md: '10px' }}
          width={{ base: '100%', md: '484px' }}
          padding={{ base: '20px 0px', md: '30px' }}
          maxW={isFullWidth ? '1440px' : '100%'}
        >
          {item.imageCtaItemTitle && <Heading size="2xl">{item.imageCtaItemTitle}</Heading>}
          {item.imageCtaItemText && (
            <Text
              fontSize={'md'}
              mt={item.imageCtaItemTitle ? '10px' : '0px'}
              mb={item.imageCtaItemLink ? '10px' : '0px'}
            >
              {item.imageCtaItemText}
            </Text>
          )}
          {item.imageCtaItemLink && (
            <Button
              as="a"
              mt={0}
              href={item.imageCtaItemLink.url}
              rightIcon={<ArrowForwardIcon />}
              size="md"
              textAlign="left"
              alignSelf="flex-start"
              target={item.imageCtaItemLink.isNewWindow ? '_blank' : '_self'}
              rel={
                item.imageCtaItemLink.isNewWindow
                  ? 'noopener' // prevents tabnabbing - security measure
                  : ''
              }
              tabIndex={isActiveSlide ? 0 : -1}
              sx={carouselBaseTheme.ctaButton}
            >
              {item.imageCtaItemLink.title}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ImageCtaCarouselItem;
