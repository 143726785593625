import {
  HeroBannerCarouselItem,
  ICategoryCtaCarouselItem,
  IGenericCtaCarouselItem,
  IImageCtaItem,
  Image,
} from '../../types';

export const getImageObj = (
  item:
    | Image
    | IImageCtaItem
    | HeroBannerCarouselItem
    | IGenericCtaCarouselItem
    | ICategoryCtaCarouselItem
): Image => {
  if ('imageCtaItemImage' in item) {
    return item.imageCtaItemImage;
  }

  if ('bannerItemImage' in item) {
    return item.bannerItemImage;
  }

  if ('genericCtaItemImage' in item) {
    return item.genericCtaItemImage;
  }

  if ('categoryCtaItemImage' in item) {
    return item.categoryCtaItemImage;
  }

  return item;
};
