import { Guest } from 'inspiretec-booking-journey-ui/api/CommonTypes';
import {
  Config,
  ProductConfig,
  ProductTypeName,
} from 'inspiretec-booking-journey-ui/api/Config/useConfig';
import {
  configureParamsOptionsFromProductConfig,
  getTotalAdults,
  getTotalChildren,
  parseUrlToSearchAvailabilityParams,
} from 'inspiretec-booking-journey-ui/api/SearchAvailability/core/helpers';
import HotelSearchAvailabilityParamsModel from 'inspiretec-booking-journey-ui/api/SearchAvailability/core/HotelSearchAvailabilityParamsModel';
import PackageSearchAvailabilityParamsModel from 'inspiretec-booking-journey-ui/api/SearchAvailability/core/PackageSearchAvailabilityParamsModel';
import {
  HotelSearchAvailabilityParams,
  PackageSearchAvailabilityParams,
} from 'inspiretec-booking-journey-ui/api/SearchAvailability/types';
import { toDisplayLocationNames } from 'inspiretec-booking-journey-ui/components/Search/SearchLocation/helpers';
import {
  localStorageKeys,
  sessionStorageKeys,
} from 'inspiretec-booking-journey-ui/constants/persistance';
import { formatToPretty, isValidDateObject } from 'inspiretec-booking-journey-ui/utils/date';
import {
  getLocalStorageValue,
  getSessionStorageValue,
} from 'inspiretec-booking-journey-ui/utils/persistentStorage';

export type PrePopulation = {
  title?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  telephone?: string;
  departure?: string;
  startDate?: string;
  destination?: string;
  duration?: number;
  adults?: number;
  children?: number;
};

export function getParsedCookieData(configData: Config | undefined) {
  const searchData = getLocalStorageValue(localStorageKeys.RECENT_SEARCH_KEY) as
    | string[]
    | undefined;
  const guestsData = getSessionStorageValue(sessionStorageKeys.PERSIST_GUESTS) as
    | Guest[]
    | undefined;

  const cookieData: PrePopulation = {};

  // Utilise Guests Data
  if (guestsData && guestsData.length) {
    const leadGuest = guestsData.find((guest) => guest.isLead);
    if (leadGuest) {
      cookieData.title = encodeURIComponent(leadGuest.title || '');
      cookieData.firstName = encodeURIComponent(leadGuest.firstName || '');
      cookieData.lastName = encodeURIComponent(leadGuest.lastName || '');
      cookieData.email = encodeURIComponent(leadGuest.emailAddress || '');
      cookieData.telephone = encodeURIComponent(leadGuest.mobileTelephoneNumber || '');
    }
  }

  // Utilise Recent Search Data
  if (searchData && searchData.length) {
    const searchParamsUrl = searchData[0];
    const searchParams = parseUrlToSearchAvailabilityParams(searchParamsUrl);
    // need to create ParamsModel to utilise all the helper functions we have made
    const productConfig = configData?.products?.find(
      (p) => p.key === searchParams.productKey
    ) as ProductConfig;
    const options = configureParamsOptionsFromProductConfig(productConfig);
    const isDynamicPackage = productConfig.type.name === ProductTypeName.DYNAMIC_PACKAGE;
    const paramModel = isDynamicPackage
      ? new PackageSearchAvailabilityParamsModel(
          productConfig,
          searchParams as PackageSearchAvailabilityParams,
          options
        )
      : new HotelSearchAvailabilityParamsModel(
          searchParams as HotelSearchAvailabilityParams,
          options
        );

    cookieData.duration = paramModel.getDuration();
    if (paramModel.data.startDate && isValidDateObject(new Date(paramModel.data.startDate))) {
      cookieData.startDate = formatToPretty(new Date(paramModel.data.startDate));
    }

    cookieData.destination = encodeURIComponent(paramModel.data.destination.name);
    cookieData.adults = getTotalAdults(paramModel.data.roomOccupancy);
    cookieData.children = getTotalChildren(paramModel.data.roomOccupancy);
    if (isDynamicPackage) {
      cookieData.departure = encodeURIComponent(
        toDisplayLocationNames((paramModel as PackageSearchAvailabilityParamsModel).data.departures)
      );
    }
  }

  return cookieData;
}

export default null;
