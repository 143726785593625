/**
 * This component is purely for testing purposes to generate errors in different ways.
 */
import { Button, HStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Module from '../Module';

const DebugErrorComponent = () => {
  const [renderError, setRenderError] = React.useState(false);
  const [hookError, setHookError] = React.useState(false);
  const onGenerateError = () => {
    alert('This will not trigger the error boundary, but should be captured by Sentry');
    // deliberate typing to any to allow error to occur.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const o: any = {};
    o.test();
  };

  const onThrowError = () => {
    alert('This will not trigger the error boundary, but should be captured by Sentry');
    throw new Error('Test Error thrown from DebugErrorComponent');
  };

  useEffect(() => {
    if (hookError) {
      throw new Error('Test Error thrown from useEffect in DebugErrorComponent');
    }
  }, [hookError]);

  return (
    <Module>
      <HStack>
        <Button variant="outline" onClick={onGenerateError}>
          Generate Script Error
        </Button>
        <Button variant="outline" onClick={onThrowError}>
          Throw Error From Event Handler
        </Button>
        <Button variant="outline" onClick={() => setRenderError(true)}>
          Generate Render Error
        </Button>
        <Button variant="outline" onClick={() => setHookError(true)}>
          Generate Hook Error
        </Button>
      </HStack>
      {renderError && <>{(renderError as any).test()}</>}
    </Module>
  );
};

DebugErrorComponent.displayName = 'DebugErrorComponent';

export default DebugErrorComponent;
