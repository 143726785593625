import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import React from 'react';

interface PaginationArrowProps {
  variant?: string;
}

const PaginationArrow = ({ variant }: PaginationArrowProps) => {
  const paginationArrowTheme = useMultiStyleConfig('PaginationArrow', {
    baseStyles: true,
    variant,
  });

  return <Box sx={paginationArrowTheme} />;
};

export default PaginationArrow;
