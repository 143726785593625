import { Box, Button, Flex, Heading, Text, useMultiStyleConfig } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';
import { CarouselBaseItem, HeroBannerCarouselItem } from '../../types';
import { getRouteFromCmsUrl } from '../../utils/routes';
import Picture from '../Picture';
import StandardContainer from '../StandardContainer';
import GradientOverlay from './GradientOverlay';

interface HeroCarouselItem extends CarouselBaseItem {
  item: HeroBannerCarouselItem;
  heroBannerCarouselIsShort?: boolean;
}

const HeroCarouselItem = ({
  item,
  heroBannerCarouselIsShort,
  index,
  isActiveSlide,
}: HeroCarouselItem) => {
  const heroCarouselItemTheme = useMultiStyleConfig('HeroCarouselItem', { baseStyles: true });

  return (
    <>
      <Box w="100%" zIndex={1}>
        <Picture
          options={{
            image: item.bannerItemImage,
            eager: index === 0,
            sizes: heroBannerCarouselIsShort
              ? [
                  [375 / (350 + 76), 100], // base //  375px width / 350px + 76px header /
                  [1.35, 100], // md
                  [1.68, 100], // lg
                  [3.57, 100], // xl - difference in version here and above
                  [3.84, 100], // 2xl 1920px +
                ]
              : [
                  [375 / (350 + 76), 100], // base //  375px width / 350px + 76px header /
                  [1.35, 100], // md
                  [1.68, 100], // lg
                  [2.36, 100], // xl
                  [2.74, 100], // 2xl 1920px +
                ],
          }}
        />
        <Flex
          className="HeroContainer"
          position="absolute"
          width="100%"
          height="100%"
          paddingTop={{ base: '72.8px', lg: '83.8px' }} // this allows space for the nav bar to center items in HeroTextContainer
          top="0"
          left="0"
          direction="column"
          justifyContent={{ base: 'flex-end', sm: 'flex-start', '2xl': 'center' }}
        >
          <GradientOverlay zIndex="1" height={{ base: '100%', md: '70%' }} />
          <StandardContainer
            className="HeroTextContainer"
            zIndex="2"
            paddingTop={{
              base: '62px', // Padding top has custom breakpoint at 500px underneath in inline sx prop.
              md: '70px',
              lg: '50px',
              xl: '64px',
              '2xl': 0,
            }}
            sx={{
              '@media (max-width: 500px)': {
                paddingTop: '16px', // under 500px override to fit space better on smaller devices. Md breakpoint too big.
              },
            }}
            paddingBottom={{ base: '62px', md: '0px' }}
            paddingX={{ base: '17px', md: '30px', lg: '70px', xl: '90px', '2xl': '35px' }}
          >
            <Flex align="flex-start" direction="column">
              {item.bannerItemTitle && (
                <Heading
                  as="h1"
                  maxW={{ base: '100%', lg: '60%' }}
                  size={{ base: '36px', md: '60px', '2xl': '72px' }}
                  lineHeight={{ base: '40px', md: '60px', '2xl': '72px' }}
                  fontWeight={'700'}
                  sx={heroCarouselItemTheme.heading}
                >
                  {item.bannerItemTitle}
                </Heading>
              )}
              {item.bannerItemSummary && (
                <Text
                  size={{ base: 'md', xl: 'lg' }}
                  maxW={{ base: '100%', md: '50%', xl: '33%' }}
                  lineHeight="1.5em"
                  marginTop={{ base: '7px', md: '14px' }}
                  sx={heroCarouselItemTheme.text}
                  fontWeight="500"
                  className="HeroSubheader"
                >
                  {item.bannerItemSummary}
                </Text>
              )}
              {item.bannerItemLink && (
                <Link passHref href={getRouteFromCmsUrl(item.bannerItemLink.url)} legacyBehavior>
                  <Button
                    as="a"
                    size="lg"
                    marginTop={{ base: '14px', md: '23px', '2xl': '33px' }}
                    colorScheme="primary"
                    sx={heroCarouselItemTheme.button}
                    tabIndex={isActiveSlide ? 0 : -1}
                    target={item.bannerItemLink?.isNewWindow ? '_blank' : '_self'}
                    rel={item.bannerItemLink?.isNewWindow ? 'noopener noreferrer' : undefined}
                  >
                    {item.bannerItemLink.title}
                  </Button>
                </Link>
              )}
            </Flex>
          </StandardContainer>
        </Flex>
      </Box>
    </>
  );
};

export default HeroCarouselItem;
