import { Box } from '@chakra-ui/react';
import React from 'react';
import { CarouselBaseItem, Image } from '../../types';
import Picture from '../Picture';

interface AlternateContentMediaCarouselItem extends CarouselBaseItem {
  item: Image;
}

const AlternateContentMediaCarouselItem = ({ item, index }: AlternateContentMediaCarouselItem) => (
  <Box
    w={{ base: '100%', lg: '100%' }}
    ml={{ base: '2px', sm: '10px', md: '10px', lg: 0 }}
    position="relative"
  >
    <Picture
      options={{
        image: item,
        eager: index === 0,
        sizes: [
          [16 / 9, 100], // >= base /sm
          [16 / 9, 100], // >= md 768px
          [16 / 9, '685px'], // >= lg 1024px
          [16 / 9, '694px'], // >= xl 1440px
          [16 / 9, '694px'], // >=2xl 1920px
        ],
      }}
    />
  </Box>
);

export default AlternateContentMediaCarouselItem;
