'use client';

import React from 'react';
import { IImageCtaCarousel } from '../../types';
import CarouselBase from '../CarouselBase';
import { CarouselVariant } from '../CarouselBase/constants';
import Module, { ModuleType } from '../Module';
import ImageCtaCarouselItem from './ImageCtaCarouselItem';

const ImageCtaCarousel = ({
  imageCtaCarouselItems,
  imageCtaCarouselIsFullWidth,
}: IImageCtaCarousel) => (
  <Module
    className="ImageCtaCarousel"
    type={imageCtaCarouselIsFullWidth ? ModuleType.FULL_WIDTH_NO_PADDING : ModuleType.DEFAULT}
  >
    <CarouselBase
      items={imageCtaCarouselItems}
      RenderComponent={ImageCtaCarouselItem}
      isFullWidth={imageCtaCarouselIsFullWidth}
      carouselVariant={CarouselVariant.IMAGE_CTA}
      paginationButtonVariant="arrowLarge"
    />
  </Module>
);

ImageCtaCarousel.displayName = 'ImageCtaCarousel';

export default ImageCtaCarousel;
