import {
  Box,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
  useMultiStyleConfig,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { CarouselBaseItem, IGenericCtaCarouselItem } from '../../types';
import formatPrice from '../../utils/formatPrice';
import ButtonLink from '../ButtonLink/ButtonLink';
import Picture from '../Picture';

interface GenericCtaCarouselItem extends CarouselBaseItem {
  item: IGenericCtaCarouselItem;
}

const GenericCtaCarouselItem = ({
  item,
  index,
  numberOfItems = 1,
  isActiveSlide,
}: GenericCtaCarouselItem) => {
  const theme = useMultiStyleConfig('GenericCtaCarousel', { baseStyles: true });

  const isMultiItem = numberOfItems > 1;

  return (
    <Flex
      direction={{ base: 'column', lg: isMultiItem ? 'column' : 'row' }}
      w={{
        base: 'full',
        md: `${100 / Math.min(numberOfItems, 2)}%`,
        lg: `${100 / Math.min(numberOfItems, 3)}%`,
      }}
      px={{ base: '10px', md: '15px', lg: '10px', xl: '20px' }}
    >
      <Box
        borderRadius={{
          base: '10px 10px 0 0',
          lg: isMultiItem ? '10px 10px 0 0' : '10px 0 0 10px',
        }}
        overflow="hidden"
        position="relative"
      >
        {item.genericCtaItemTags && (
          <List pos="absolute" my="5px" mx="5px">
            {item.genericCtaItemTags.map((tag, index) => (
              <ListItem
                key={tag + index}
                display="inline-block"
                mx="5px"
                my="5px"
                py={2}
                px={4}
                borderRadius="50px"
                sx={theme.tag}
              >
                <Text fontSize="xs" mt={0} fontWeight="600">
                  {tag}
                </Text>
              </ListItem>
            ))}
          </List>
        )}
        <Picture
          options={{
            image: item.genericCtaItemImage,
            eager: index === 0,
            sizes: isMultiItem
              ? [
                  [1.77, 100], // >= base / sm
                  [1.77, '477px'], // >= md 768px
                  [1.77, '450px'], // >= lg 1024px
                  [1.77, '456px'], // >= xl 1440px
                  [1.77, '456px'], // >=2xl 1920px
                ]
              : [
                  [1.77, 100], // >= base / sm
                  [1.77, 100], // >= md 768px
                  [1.77, 68], // >= lg 1024px // 57% width for image
                  [2.3, 68], // >= xl 1440px // 68% width for image
                  [2.3, 68], // >= 2xl 1920px
                ],
          }}
        />
      </Box>
      <Box
        display={isMultiItem ? 'block' : 'flex'}
        flexDirection="column"
        justifyContent="center"
        px={isMultiItem ? { base: '15px', md: 5 } : { base: '15px', md: 5, lg: 10 }}
        pt={3}
        pb={5}
        border="1px solid #E2E8F0"
        borderTop={{ base: 0, lg: isMultiItem ? 0 : '1px solid #E2E8F0' }}
        borderLeft={{ base: '1px solid #E2E8F0', lg: isMultiItem ? '1px solid #E2E8F0' : 0 }}
        borderRadius={{
          base: '0 0 10px 10px',
          lg: isMultiItem ? '0 0 10px 10px' : '0 10px 10px 0',
        }}
        minW="30%"
      >
        <Box>
          <Heading lineHeight={2} size={{ base: 'lg', '2xl': '2xl' }}>
            {item.genericCtaItemTitle}
          </Heading>
          {item.genericCtaItemText && (
            <Text fontSize="sm" mt="10px">
              {item.genericCtaItemText}
            </Text>
          )}
          <Flex
            flexDirection={{ base: 'column', md: 'row', lg: 'column' }}
            justifyContent="space-between"
          >
            {item.genericCtaItemHasPrice && item.genericCtaItemPrice && (
              <VStack align="stretch" spacing={0}>
                <Text fontSize="sm" mt="10px">
                  From
                </Text>
                <Text fontSize="3xl" mt={0} fontWeight="bold">
                  £{formatPrice(item.genericCtaItemPrice)}
                  <Text as="span" fontSize="sm" fontWeight="normal" ml="6px">
                    Per person
                  </Text>
                </Text>
              </VStack>
            )}
            {item.genericCtaItemLink && (
              <ButtonLink
                sx={{
                  ...theme.carouselItemCta,
                  mt: isMultiItem ? '10px' : 3,
                }}
                key={item.genericCtaItemLink.url}
                link={item.genericCtaItemLink}
                name="Generic CTA Item Link"
                variant="outline"
                tabIndex={isActiveSlide ? 0 : -1}
              >
                {item.genericCtaItemLink.title}
              </ButtonLink>
            )}
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default GenericCtaCarouselItem;
