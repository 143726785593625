import { Box, Heading, Link as Anchor, useStyleConfig } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';
import { CarouselBaseItem, ICategoryCtaCarouselItem } from '../../types';
import Picture, { PictureSizes } from '../Picture';

interface CategoryCtaCarouselItem extends CarouselBaseItem {
  item: ICategoryCtaCarouselItem;
}

// Flickity uses absolute positioning (we can't use flex/grid), we have to set custom classes
const getSlidesToShow = (slidesToShow: number) => {
  if (slidesToShow <= 4) {
    return `show-${slidesToShow}-slides`;
  }
  return 'show-4-slides'; // max 4 slides on screen in carousel
};

const getPictureSizesForCategoryCtaCarouselItem = (numberOfItems: number): PictureSizes => {
  switch (numberOfItems) {
    case 1:
      return [
        [1.39, '690px'], // sm
        [2, '768px'], // md
        [3.31, '768px'], // lg
        [3.31, '768px'], // xl
        [3.31, '768px'], // 2xl
      ];
    case 2:
      return [
        [1.39, '690px'], // sm
        [0.97, '466px'], // md
        [1.61, '466px'], // lg
        [1.61, '466px'], // xl
        [1.61, '466px'], // 2xl
      ];
    case 3:
      return [
        [1.39, '690px'], // sm
        [0.97, '466px'], // md
        [0.9, '466px'], // lg
        [1.04, '466px'], // xl
        [1.04, '466px'], // 2xl
      ];
    default:
      if (numberOfItems >= 4) {
        return [
          [1.39, '690px'], // sm
          [0.97, '382px'], // md
          [0.9, '382px'], // lg
          [0.75, '320px'], // xl
          [0.75, '320px'], // 2xl
        ];
      }
      // Default case if none of the above matches
      return [
        [1.39, '690px'], // sm
        [0.97, '382px'], // md
        [0.9, '382px'], // lg
        [0.75, '320px'], // xl
        [0.75, '320px'], // 2xl
      ];
  }
};

const CategoryCtaCarouselItem = ({ item, numberOfItems = 1, index }: CategoryCtaCarouselItem) => {
  const { categoryCtaItemImage, categoryCtaItemLink } = item;
  const styles = useStyleConfig('CategoryCtaCarouselItem', {});
  return (
    <Box
      className={getSlidesToShow(numberOfItems)}
      px={{ base: 1, md: 4 }}
      tabIndex={-1}
      sx={styles}
    >
      {categoryCtaItemLink?.url && (
        <Link href={categoryCtaItemLink?.url} as={categoryCtaItemLink?.url} passHref legacyBehavior>
          <Anchor
            target={categoryCtaItemLink.isNewWindow ? '_blank' : '_self'}
            rel={categoryCtaItemLink.isNewWindow ? 'noopener noreferrer' : undefined}
          >
            <Box position="relative" borderRadius="10" overflow="hidden">
              {categoryCtaItemImage && (
                <Picture
                  options={{
                    image: {
                      url: categoryCtaItemImage.url,
                      alt: categoryCtaItemImage.alt,
                      focalPointLeft: categoryCtaItemImage.focalPointLeft,
                      focalPointTop: categoryCtaItemImage.focalPointTop,
                    },
                    eager: index === 0,
                    sizes: getPictureSizesForCategoryCtaCarouselItem(numberOfItems),
                  }}
                />
              )}
              <Heading
                _before={{
                  content: '""',
                  display: 'block',
                  w: '100%',
                  h: '200%',
                  position: 'absolute',
                  background:
                    'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.69) 100%);',
                  left: '0',
                  top: '0',
                  zIndex: '0',
                }}
                color="white"
                as="h3"
                fontSize={{ base: '20px', md: '24px' }}
                position="absolute"
                bottom="0"
                left="0"
                width="100%"
                p="25px"
              >
                <Box zIndex="1" position="relative">
                  {categoryCtaItemLink.title}
                </Box>
              </Heading>
            </Box>
          </Anchor>
        </Link>
      )}
    </Box>
  );
};

export default CategoryCtaCarouselItem;
