import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Spinner,
} from '@chakra-ui/react';
import useConfig from 'inspiretec-booking-journey-ui/api/Config/useConfig';
import { setCookie } from 'inspiretec-booking-journey-ui/utils/cookies';
import { logErrorToSentry } from 'inspiretec-booking-journey-ui/utils/sentryHelpers';
import DOMPurify from 'isomorphic-dompurify';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useBrochurewareDictionary from '../../api/Dictionary/useBrochurewareDictionary';
import { FORM_PRE_POPULATION } from '../../constants/cookies';
import { CULTURE } from '../../constants/i18n';
import useForm from '../../queries/content/useForm';
import regex from '../../utils/regex';
import Module, { ModuleType } from '../Module';
import { getParsedCookieData, PrePopulation } from './helper';

interface UmbracoFormProps {
  id: string;
}

// jQuery scripts for validation, moment (native) required by pikaday library for datepicker
// reCAPTCHA (without render param) for reCAPTCHA v2
const PAGE_SCRIPTS = [
  'https://ajax.aspnetcdn.com/ajax/jQuery/jquery-3.6.1.min.js',
  'https://ajax.aspnetcdn.com/ajax/jquery.validate/1.19.1/jquery.validate.min.js',
  'https://ajax.aspnetcdn.com/ajax/mvc/5.2.3/jquery.validate.unobtrusive.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.4/moment-with-locales.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/pikaday/1.8.2/pikaday.min.js',
  'https://www.google.com/recaptcha/api.js',
];

const UmbracoForm = ({ id: formId }: UmbracoFormProps) => {
  const { data: configData } = useConfig();
  const { errors } = useBrochurewareDictionary('UmbracoForm');
  const { getFormBody, formBody, isLoading: isLoadingFormBody, error } = useForm();
  const [loadedScripts, setLoadedScripts] = useState<Array<string>>([]);
  const [allScriptsLoaded, setAllScriptsLoaded] = useState<boolean>(false);
  const [recaptchaV3Loaded, setRecaptchaV3Loaded] = useState<boolean>(false);
  const [triggerScriptsLoad, setTriggerScriptsLoad] = useState<boolean>(false);
  const pikadaysRef = useRef<Pikaday[]>();

  const scriptsContainerId = 'scripts-container';

  const updatePrePopulationCookie = useCallback(() => {
    try {
      const cookieData: PrePopulation = getParsedCookieData(configData);

      // set the cookie to expire in 1 day
      const expires = new Date(new Date().setDate(new Date().getDate() + 1)).toUTCString();

      setCookie({ name: FORM_PRE_POPULATION, expires, path: '/' }, JSON.stringify(cookieData));
    } catch (e) {
      console.warn('UmbracoForm could not parse cookie data to pre populate', e);
    }
  }, []);

  const createScriptTag = (src: string, callback?: () => void) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    if (callback) {
      script.onload = callback;
    }

    return script;
  };

  const loadNextScript = () => {
    const scriptContainer = document.querySelector(`#${scriptsContainerId}`);

    if (!scriptContainer) {
      console.error('Script parent container not found');
      return;
    }

    const pendingScripts = PAGE_SCRIPTS.filter((x) => !loadedScripts.includes(x));

    if (!pendingScripts.length) {
      setAllScriptsLoaded(true);
      return;
    }

    if (scriptContainer.querySelectorAll(`script[src='${pendingScripts[0]}']`).length) {
      setLoadedScripts((prev) => [...prev, pendingScripts[0]]);
      return;
    }

    const script = createScriptTag(pendingScripts[0], () => {
      setLoadedScripts((prev) => [...prev, pendingScripts[0]]);
    });

    scriptContainer.appendChild(script);
  };

  const initialiseDatepickers = () => {
    // Logic based on umbraco's datepicker.init.js
    const pikadayConfig = document.getElementById('umbraco-forms-date-picker-config');

    if (!pikadayConfig) {
      return;
    }

    window.moment.locale(CULTURE);

    const fields = document.getElementsByClassName('datepickerfield');
    const pikadayList = [];

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i] as HTMLInputElement;

      pikadayList.push(
        new window.Pikaday({
          field: field,
          yearRange: Number(pikadayConfig.dataset.yearRange),
          i18n: {
            previousMonth: pikadayConfig.dataset.previousMonth,
            nextMonth: pikadayConfig.dataset.nextMonth,
            months: pikadayConfig.dataset.months?.split(','),
            weekdays: pikadayConfig.dataset.weekdays?.split(','),
            weekdaysShort: pikadayConfig.dataset.weekdaysShort?.split(','),
          } as Pikaday.PikadayI18nConfig,
          format: 'DD/MM/YYYY',
          minDate: new Date('1753-01-01T00:00:00'), //Min value of datetime in SQL Server CE
          defaultDate: new Date(field.value),
          setDefaultDate: true,
          theme: 'standalone',
        })
      );
    }

    pikadaysRef.current = pikadayList;

    return () => {
      pikadaysRef.current?.forEach((pikaday) => pikaday.destroy());
    };
  };

  const initialiseRecaptchaV3 = () => {
    const scriptContainer = document.querySelector(`#${scriptsContainerId}`);

    if (!scriptContainer) {
      console.error('Script parent container not found');
      return;
    }

    const recaptchaV3Config = document.querySelector(
      '.umbraco-forms-recaptcha-v3-config'
    ) as HTMLElement;

    if (!recaptchaV3Config) {
      // if there are no recaptchaV3 components, set recaptchaV3Loaded to true to allow UI to load
      setRecaptchaV3Loaded(true);
      return;
    }

    const siteKey = recaptchaV3Config.dataset.siteKey;
    const recaptchaV3Src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;

    if (scriptContainer.querySelectorAll(`script[src='${recaptchaV3Src}']`).length) {
      return;
    }

    const script = createScriptTag(recaptchaV3Src, () => {
      // Logic based on umbraco's recaptcha.v3.init.js
      const timerFunction = (hiddenField: HTMLInputElement) => {
        try {
          window.grecaptcha
            .execute(siteKey, { action: 'umbracoform_submit' })
            .then((token: string) => {
              hiddenField.form?.querySelector('[type=submit]')?.removeAttribute('disabled');
              hiddenField.value = token;
            });
        } catch (e) {
          logErrorToSentry(e);
          console.error('Error executing reCAPTCHA v3', e);
        }
        setTimeout(timerFunction, 60 * 1000, hiddenField);
      };

      Array.from(document.getElementsByClassName('umbraco-forms-recaptcha-v3-config')).forEach(
        (configElement) => {
          const configElementData = (configElement as HTMLElement).dataset;
          if (configElementData.id) {
            const hiddenField = document.getElementById(configElementData.id) as HTMLInputElement;

            hiddenField.form?.querySelector('[type=submit]')?.setAttribute('disabled', 'disabled');

            window.grecaptcha.ready(function () {
              timerFunction(hiddenField);
            });
          }
        }
      );

      setRecaptchaV3Loaded(true);
    });

    scriptContainer.appendChild(script);
  };

  const injectUtmParams = (formElement: HTMLFormElement | null | undefined) => {
    try {
      if (formElement) {
        const action = formElement.getAttribute('action');
        if (action) {
          const actionComponents = action.split('?');
          if (actionComponents.length !== 2) return;
          const actionParams = new URLSearchParams(actionComponents[1]);
          const redirectUrl = actionParams.get('redirectUrl');
          actionParams.set('redirectUrl', `${redirectUrl}${decodeURI(window.location.search)}`);
          actionComponents[1] = actionParams.toString();
          formElement.setAttribute('action', actionComponents.join('?'));
        }
      }
    } catch (e) {
      console.error('Something went wrong injecting utm params!');
    }
  };

  const onSubmit = (event: SubmitEvent) => {
    const recaptchaV2Element = document.querySelector('.g-recaptcha');

    if (recaptchaV2Element) {
      const recaptchaV2ResponseElement = recaptchaV2Element.querySelector(
        '#g-recaptcha-response'
      ) as HTMLInputElement;
      const errorMessageElement = recaptchaV2Element.nextElementSibling as HTMLElement;

      if (recaptchaV2ResponseElement?.value === '') {
        errorMessageElement.className = 'field-validation-error';
        errorMessageElement.textContent = 'Please verify that you are not a robot.';
        event.preventDefault();
      } else {
        errorMessageElement.className = 'field-validation-valid';
        errorMessageElement.textContent = '';
      }
    }
  };

  useEffect(() => {
    if (document.querySelector(`#${scriptsContainerId}`)) return;

    const scriptContainer = document.createElement('div');
    scriptContainer.id = scriptsContainerId;
    document.body.appendChild(scriptContainer);

    return () => {
      document.body.removeChild(scriptContainer);
    };
  }, []);

  useEffect(() => {
    if (!loadedScripts.length) return;
    loadNextScript();
  }, [loadedScripts]);

  useEffect(() => {
    if (!triggerScriptsLoad) return;
    loadNextScript();
  }, [triggerScriptsLoad]);

  useEffect(() => {
    if (!formBody?.formHtml || !formBody.formHtml.length) return;
    setTriggerScriptsLoad(true);
  }, [formBody?.formHtml]);

  useEffect(
    function onMountLoadFormBody() {
      updatePrePopulationCookie();
      getFormBody(formId);
    },
    [getFormBody, updatePrePopulationCookie]
  );

  useEffect(() => {
    if (allScriptsLoaded) {
      const hiddenInput = document.createElement('input');
      hiddenInput.type = 'hidden';
      hiddenInput.name = '_culture';
      hiddenInput.value = CULTURE;

      const formElement = document
        .getElementById(`umbraco_form_${formBody?.id.replace(regex.ALL_HYPHENS, '')}`)
        ?.querySelector('form');

      formElement?.appendChild(hiddenInput);

      injectUtmParams(formElement);

      const cleanUpDatepickers = initialiseDatepickers();

      formElement?.addEventListener('submit', onSubmit);

      initialiseRecaptchaV3();

      return () => {
        if (cleanUpDatepickers) cleanUpDatepickers();

        formElement?.removeEventListener('submit', onSubmit);
      };
    }
  }, [allScriptsLoaded]);

  return (
    <Module className="Form" type={ModuleType.CONTENT_WIDTH}>
      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{errors('loadErrorTitle')}</AlertTitle>
          <AlertDescription>{errors('loadErrorText')}</AlertDescription>
        </Alert>
      )}
      {formBody?.formHtml && formBody?.scriptsHtml && (
        <Box hidden={!allScriptsLoaded || !recaptchaV3Loaded}>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(formBody.formHtml, { SANITIZE_DOM: false }),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(formBody.scriptsHtml, { SANITIZE_DOM: false }),
            }}
          />
        </Box>
      )}
      {(isLoadingFormBody || !allScriptsLoaded || !recaptchaV3Loaded) && !error && (
        <Center>
          <Spinner />
        </Center>
      )}
    </Module>
  );
};

UmbracoForm.displayName = 'UmbracoForm';

export default UmbracoForm;
