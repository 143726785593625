import getConfig from 'next/config';

export const SCRIPTS_CONTAINER_ID = 'scripts-container';

const createScriptTag = (src: string, callback?: () => void) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;

  if (callback) {
    script.onload = callback;
  }

  return script;
};

type RecaptchaCallbackSignature = (success: boolean) => void;
/**
 * Adds the Google Recaptcha Script tag to the document so we can call the
 * window.grecaptcha class for validating forms & inputs
 * @param {RecaptchaCallbackSignature} callback - Runs on completion with a boolean success param
 * @returns {void}
 */
export const initRecaptchaV3 = (callback?: RecaptchaCallbackSignature) => {
  try {
    const { publicRuntimeConfig } = getConfig();
    const siteKey = publicRuntimeConfig.recaptchaSiteKey;
    const recaptchaV3Src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;

    // Check if we already have the recaptcha script on the page.
    const scriptTags = document.querySelectorAll(`[src="${recaptchaV3Src}"]`);
    if (scriptTags.length > 0) {
      callback?.(true);
      return;
    }

    let scriptContainer = document.querySelector(`#${SCRIPTS_CONTAINER_ID}`);

    if (!scriptContainer) {
      const newScriptContainer = document.createElement('div');
      newScriptContainer.id = SCRIPTS_CONTAINER_ID;
      document.body.appendChild(newScriptContainer);
      scriptContainer = newScriptContainer;
    }

    const script = createScriptTag(recaptchaV3Src);

    scriptContainer.appendChild(script);

    callback?.(true);
  } catch (error) {
    callback?.(false);
  }
};
