import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { IAlternateContentMediaItem } from '../../types';
import MediaContent from './MediaContent';
import TextContent from './TextContent';

interface IAlternateContentMediaItemProps {
  data: IAlternateContentMediaItem;
  alternateContentMediaTitle: string;
  index: number;
}

const AlternateContentMediaItem = ({
  data,
  alternateContentMediaTitle,
  index,
}: IAlternateContentMediaItemProps) => {
  const isOddRow = index % 2 !== 0;

  return (
    <Flex
      direction={isOddRow ? { base: 'column', lg: 'row-reverse' } : { base: 'column', lg: 'row' }}
      mt={{
        base: 4.5,
        sm: 7,
        md: 8,
        lg: 8,
        xl: 9,
      }}
      gap={5}
      sx={{ width: '100%' }}
      alignItems="center"
      wrap="nowrap"
    >
      <Box flex="1" sx={{ width: '100%' }}>
        <MediaContent
          alternateContentMediaItemVideo={data.alternateContentMediaItemVideo}
          alternateContentMediaItemImages={data.alternateContentMediaItemImages}
        />
      </Box>
      <Box flex="1" sx={{ width: '100%' }}>
        <TextContent
          alternateContentMediaItemLink={data.alternateContentMediaItemLink}
          alternateContentMediaItemText={data.alternateContentMediaItemText}
          alternateContentMediaItemTitle={data.alternateContentMediaItemTitle}
          alternateContentMediaTitle={alternateContentMediaTitle}
          isOddRow={isOddRow}
        />
      </Box>
    </Flex>
  );
};

export default AlternateContentMediaItem;
