import { Button, Flex, Heading, useMultiStyleConfig } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';
import { IGenericCtaCarousel } from '../../types';
import CarouselBase from '../CarouselBase';
import { CarouselVariant } from '../CarouselBase/constants';
import Module, { ModuleType } from '../Module';
import GenericCtaCarouselItem from './GenericCtaCarouselItem';

const GenericCtaCarousel = ({
  genericCtaCarouselItems,
  genericCtaCarouselLink,
  genericCtaCarouselTitle,
}: IGenericCtaCarousel) => {
  const theme = useMultiStyleConfig('GenericCtaCarousel', { baseStyles: true });

  return (
    <Module className="GenericCtaCarousel" type={ModuleType.DEFAULT} sx={theme.module}>
      <Flex
        justifyContent="space-between"
        mb={genericCtaCarouselTitle || genericCtaCarouselLink ? 8 : 0}
        align="center"
      >
        {genericCtaCarouselTitle && (
          <Heading fontSize={{ base: '2xl', '2xl': '3xl' }}>{genericCtaCarouselTitle}</Heading>
        )}
        {genericCtaCarouselLink && (
          <Link
            href={genericCtaCarouselLink?.url}
            as={genericCtaCarouselLink?.url}
            passHref
            legacyBehavior
          >
            <Button
              as="a"
              variant="outline"
              ml="auto"
              tabIndex={-1}
              display={{ base: 'none', md: 'inline-flex' }}
              sx={theme.carouselCta}
              target={genericCtaCarouselLink?.isNewWindow ? '_blank' : '_self'}
              rel={genericCtaCarouselLink?.isNewWindow ? 'noopener noreferrer' : undefined}
            >
              {genericCtaCarouselLink.title}
            </Button>
          </Link>
        )}
      </Flex>
      <CarouselBase
        items={genericCtaCarouselItems}
        RenderComponent={GenericCtaCarouselItem}
        carouselVariant={CarouselVariant.GENERIC_CTA}
        paginationButtonVariant="chevron"
      />
      {genericCtaCarouselLink && (
        <Link
          href={genericCtaCarouselLink?.url}
          as={genericCtaCarouselLink?.url}
          passHref
          legacyBehavior
        >
          <Button
            as="a"
            variant="outline"
            tabIndex={-1}
            w="full"
            display={{ base: 'inline-flex', md: 'none' }}
            mt={5}
            sx={theme.carouselCta}
            target={genericCtaCarouselLink?.isNewWindow ? '_blank' : '_self'}
            rel={genericCtaCarouselLink?.isNewWindow ? 'noopener noreferrer' : undefined}
          >
            {genericCtaCarouselLink.title}
          </Button>
        </Link>
      )}
    </Module>
  );
};

GenericCtaCarousel.displayName = 'GenericCtaCarousel';

export default GenericCtaCarousel;
