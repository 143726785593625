import { Box } from '@chakra-ui/react';
import React from 'react';

export const DarkenedOverlay = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        background: 'linear-gradient(0deg, rgba(0,0,0,0.3), rgba(0,0,0,0.3))',
        pointerEvents: 'none',
        borderRadius: '10px',
      }}
    />
  );
};
