import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  ResponsiveValue,
  SystemStyleObject,
} from '@chakra-ui/react';
import * as CSS from 'csstype';
import Link from 'next/link';
import React from 'react';
import { Link as HeroBannerLink } from '../../types';
import { getRouteFromCmsUrl } from '../../utils/routes';
import StandardContainer from '../StandardContainer';

interface HeroContentProps {
  children?: React.ReactNode;
  link?: HeroBannerLink | null | undefined;
  title: string;
  theme: Record<string, SystemStyleObject>;
  heroPlaceholderIsAboveHeroOnDesktop?: boolean;
}

const HeroContent = ({
  title,
  theme,
  link,
  children,
  heroPlaceholderIsAboveHeroOnDesktop,
}: HeroContentProps) => {
  /* This link always displays below the title but only hidden on xl when
  search is in the middle of the hero */
  let displayLinkBelowTitle: string | ResponsiveValue<CSS.Property.Display> = 'block';
  if (children && !heroPlaceholderIsAboveHeroOnDesktop) {
    displayLinkBelowTitle = { xl: 'none' };
  }

  /* This link only displays when it appears just below the search on xl and
  search is not displayed above hero, ergo in the middle of the hero at xl */
  const displayLinkBelowSearch: string | ResponsiveValue<CSS.Property.Display> =
    children && !heroPlaceholderIsAboveHeroOnDesktop ? { base: 'none', xl: 'block' } : 'none';

  const renderLink = (
    link: HeroBannerLink | undefined | null,
    displayObject: ResponsiveValue<CSS.Property.Display> | string,
    isBelowSearchLink?: boolean
  ) => {
    if (!link) {
      return null;
    }
    return (
      <Box display={displayObject} textAlign="center">
        <Link passHref href={getRouteFromCmsUrl(link.url)} legacyBehavior>
          <Button
            className="HeroBannerContentLink"
            as="a"
            size="lg"
            colorScheme="primary"
            width="auto"
            mt={isBelowSearchLink ? 10 : 0}
            mb={{ base: 0, lg: 10, xl: 0 }} // only break point where we want to add margin
            sx={theme.button}
            target={link?.isNewWindow ? '_blank' : '_self'}
            rel={link?.isNewWindow ? 'noopener noreferrer' : undefined}
          >
            {link.title}
          </Button>
        </Link>
      </Box>
    );
  };

  return (
    <StandardContainer className="HeroBannerContentContainer" sx={theme.content} flexGrow={1}>
      <Flex align="center" direction="column" h="full" justifyContent="center" alignItems="stretch">
        {(title || link) && (
          <Center flexDirection="column">
            {title && (
              <Heading
                as="h1"
                maxW={{ base: '100%', md: '90%', lg: '80%', xl: '60%' }}
                mt={{ base: 0, lg: 24, xl: 0 }} // only break point where we want to add margin
                mb="0.5em"
                size="4xl"
                textAlign={{ base: 'center', md: 'left' }}
                sx={theme.heading}
              >
                {title}
              </Heading>
            )}

            {/* Link that renders below the title, but only hidden on xl when search is in the middle of the hero */}
            {renderLink(link, displayLinkBelowTitle)}
          </Center>
        )}

        {children}

        {/* Link that renders below the search on xl */}
        {children && <>{renderLink(link, displayLinkBelowSearch, true)}</>}
      </Flex>
    </StandardContainer>
  );
};

export default HeroContent;
