import { Box } from '@chakra-ui/react';
import React from 'react';
import { Image } from '../../types';
import CarouselBase from '../CarouselBase';
import { CarouselVariant } from '../CarouselBase/constants';
import Picture from '../Picture';
import VideoBase from '../VideoBase';
import AlternateContentMediaCarouselItem from './AlternateContentMediaCarouselItem';

interface MediaContentProps {
  alternateContentMediaItemVideo?: string;
  alternateContentMediaItemImages: Image[];
}

const MediaContent = ({
  alternateContentMediaItemVideo,
  alternateContentMediaItemImages,
}: MediaContentProps) => {
  return (
    <Box flex="1">
      {alternateContentMediaItemVideo ? (
        <VideoBase
          videoLink={alternateContentMediaItemVideo}
          videoPlaceholderImage={alternateContentMediaItemImages[0]}
        />
      ) : alternateContentMediaItemImages.length === 1 ? (
        <Picture
          options={{
            image: alternateContentMediaItemImages[0],
            sizes: [
              [16 / 9, 100], // >= base /sm
              [16 / 9, 100], // >= md 768px
              [16 / 9, '685px'], // >= lg 1024px
              [16 / 9, '694px'], // >= xl 1440px
              [16 / 9, '694px'], // >=2xl 1920px
            ],
          }}
          sx={{ borderRadius: '10px' }}
        />
      ) : (
        <CarouselBase
          items={alternateContentMediaItemImages}
          RenderComponent={AlternateContentMediaCarouselItem}
          carouselVariant={CarouselVariant.IMAGE}
          paginationButtonVariant="arrowSmall"
        />
      )}
    </Box>
  );
};

export default MediaContent;
