import { Box } from '@chakra-ui/react';
import React from 'react';

const GradientOverlay = (props: any) => (
  <Box
    {...props}
    height="0"
    _after={{
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: props.height,
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.39) 100%)',
      transform: 'rotate(-180deg)',
    }}
  />
);

export default GradientOverlay;
