'use client';
import { Box, Spinner, VisuallyHidden } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import React, { useEffect, useRef, useState } from 'react';
import { ReactPlayerProps } from 'react-player';
import BaseReactPlayer from 'react-player/base';
import { MEDIA_BASE_PATH } from '../../constants/routes';
import { IVideo } from '../../types';
import { DarkenedOverlay } from './DarkenedOverlay';
import PlayButton from './PlayButton';
import { getVideoImagePlaceholder } from './videoHelpers';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

interface IVideoPlayerProps extends IVideo {
  onPlay?: () => void;
  onPause?: () => void;
  onEnd?: () => void;
}

const VideoBase = ({
  videoLink,
  videoPlaceholderImage,
  onPlay,
  onPause,
  onEnd,
}: IVideoPlayerProps) => {
  const reactPlayerRef = useRef<BaseReactPlayer<ReactPlayerProps>>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const [videoStarted, setVideoStarted] = useState(false);
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [videoPlayerWidth, setVideoPlayerWidth] = useState(0);

  const [description, setDescription] = useState('');
  const hasAnnouncedRef = useRef(false);

  const videoImage = `${MEDIA_BASE_PATH}${videoPlaceholderImage.url}`;

  useEffect(() => {
    if (innerRef.current) {
      setVideoPlayerWidth(innerRef.current.offsetWidth);
    }
  }, []);

  const handlePlay = () => {
    setVideoPlaying(true);
    onPlay?.();
  };

  const handlePause = () => {
    setVideoPlaying(false);
    onPause?.();
  };

  const handleEnd = () => {
    setVideoPlaying(false);
    onEnd?.();
  };

  const handleFocus = () => {
    if (!hasAnnouncedRef.current) {
      setDescription(`Background image of video: ${videoPlaceholderImage.alt}`);
      hasAnnouncedRef.current = true;
    }
  };

  const handleBlur = () => {
    setDescription('');
    hasAnnouncedRef.current = false;
  };

  return (
    <Box
      ref={innerRef}
      onFocus={handleFocus}
      onBlur={handleBlur}
      sx={{
        position: 'relative',
        height: 0,
        paddingBottom: '56.25%' /* Aspect ratio 16:9 */,
        width: '100%',
        // React Player Style Overrides
        // KNOWN ISSUE:  applying border radius & overflow: hidden to the react player
        // causes issues with the clickable area being limited to the bottom right corner.
        '.react-player__preview': {
          borderRadius: '10px',
          position: 'relative',
        },
        '.react-player__preview:focus-visible': {
          outline: 0,
        },
        '.react-player__preview:focus-visible::after': {
          content: '""',
          position: 'absolute',
          top: '-4px',
          left: '-4px',
          right: '-4px',
          bottom: '-4px',
          border: '5px solid rgba(243, 169, 0, 0.6)' /* Focus ring */,
          borderRadius: '14px' /* Adjusted to match the border-radius + focus ring width */,
        },
      }}
    >
      <Box
        className="react-player-parent"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {!videoStarted && <DarkenedOverlay />}
        <ReactPlayer
          fallback={<Spinner />}
          url={videoLink}
          playing={isVideoPlaying}
          ref={reactPlayerRef}
          onPlay={handlePlay}
          onPause={handlePause}
          onEnded={handleEnd}
          onClickPreview={() => {
            setVideoPlaying(true);
            setVideoStarted(true);
          }}
          onStart={() => {
            setVideoStarted(true);
          }}
          light={getVideoImagePlaceholder(videoImage, videoPlayerWidth)}
          playIcon={<PlayButton />}
          width={'100%'}
          height={'100%'}
          data-testid="video-preview"
          config={{
            youtube: {
              playerVars: {
                // ISSUE: Despite correct config, YouTube failing to autoplay on iOS devices in browserstack - requires 2 clicks.
                autoplay: 1, // autoplay means when clicked on placeholder in this context.
                muted: 1, // Plays muted
                controls: 0,
                playsinline: 1 /* This is to enable autoplay on iOS */,
                rel: 0, // Defines whether related videos should be shown at the end of the video.
                iv_load_policy: 3, // hides video annotations in YouTube
                modestbranding: 1, // prevents the YouTube logo from appearing in the control bar.
                enablejsapi: 1, // Enables the JavaScript API handlers.
              },
            },
            vimeo: {
              playerOptions: {
                autoplay: true, // autoplay means when clicked on placeholder in this context.
                muted: true,
                controls: true,
                playsinline: true /* This is to enable autoplay on iOS */,
              },
            },
          }}
        />
      </Box>
      <VisuallyHidden aria-live="assertive">{description}</VisuallyHidden>
    </Box>
  );
};

export default VideoBase;
