import React from 'react';
import { PageTheme } from '../../theme';
import AlternateContentMedia from '../AlternateContentMedia';
import ByWay from '../ByWay';
import CategoryCtaCarousel from '../CategoryCtaCarousel';
import ContentBlock from '../ContentBlock';
import DebugErrorComponent from '../Error/DebugErrorComponent';
import { withErrorBoundary } from '../ErrorBoundary';
import ExpandingContentList from '../ExpandingContentList';
import GenericCtaCarousel from '../GenericCtaCarousel';
import HeroBanner from '../HeroBanner';
import HeroBannerCarousel from '../HeroBannerCarousel';
import ImageCtaCarousel from '../ImageCtaCarousel';
import NewsletterSubscription from '../NewsletterSubscription';
import SearchBar from '../Search';
import TabbedContentBlock from '../TabbedContentBlock';
import UmbracoForm from '../UmbracoForm';
import Video from '../Video';

interface DynamicComponent {
  isModule: boolean;
  id: string;
  contentType: string;
  hasTransparentHeader?: boolean;
  properties: any;
  pageTheme: PageTheme;
  [key: string]: any;
}

interface AvailableComponents {
  [key: string]: (props: any) => JSX.Element;
}

const DynamicComponent = (props: DynamicComponent) => {
  const availableComponents: AvailableComponents = {
    alternateContentMedia: AlternateContentMedia,
    byWay: ByWay,
    bookingJourneySearch: SearchBar,
    categoryCtaCarousel: CategoryCtaCarousel,
    contentBlock: ContentBlock,
    debugError: DebugErrorComponent,
    tabbedContentBlock: TabbedContentBlock,
    expandingContentList: ExpandingContentList,
    form: UmbracoForm,
    genericCtaCarousel: GenericCtaCarousel,
    imageCtaCarousel: ImageCtaCarousel,
    heroBannerCarousel: HeroBannerCarousel,
    heroBanner: HeroBanner,
    newsletterSubscription: NewsletterSubscription,
    video: Video,
  };

  if (!availableComponents[props.contentType]) {
    console.warn(`Can't find matching DynamicComponent for ${props.contentType}`);
    return null;
  }

  const FoundComponent = withErrorBoundary(availableComponents[props.contentType]);

  return (
    <FoundComponent
      key={props.id}
      {...props.properties}
      hasTransparentHeader={props.hasTransparentHeader}
      isModule={props.isModule}
      pageTheme={props.pageTheme}
    />
  );
};

DynamicComponent.displayName = 'DynamicComponent';

export default DynamicComponent;
