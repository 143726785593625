import React, { createContext, Suspense, useContext } from 'react';
import { INewsletterSubscription } from '../../types';
import Module, { ModuleType } from '../Module';
import NewsletterSubscriptionForm from './components/NewsletterSubscriptionForm';
import NewsletterSubscriptionImage from './components/NewsletterSubscriptionImage';
import NewsletterSubscriptionIntro from './components/NewsletterSubscriptionIntro';
import NewsletterSubscriptionLayout from './components/NewsletterSubscriptionLayout';
import NewsletterSubscriptionOutro from './components/NewsletterSubscriptionOutro';

const NewsletterContext = createContext<INewsletterSubscription | null>(null);

const NewsletterSubscription = (props: INewsletterSubscription) => {
  return (
    <Module className="NewsletterSubscription" type={ModuleType.DEFAULT}>
      <Suspense>
        <NewsletterContext.Provider value={props}>
          <NewsletterSubscriptionLayout>
            {props.image && <NewsletterSubscriptionImage />}
            <NewsletterSubscriptionLayout.Right>
              <NewsletterSubscriptionIntro />
              <NewsletterSubscriptionForm />
              <NewsletterSubscriptionOutro />
            </NewsletterSubscriptionLayout.Right>
          </NewsletterSubscriptionLayout>
        </NewsletterContext.Provider>
      </Suspense>
    </Module>
  );
};

export const useNewsletterContext = () => {
  const ctx = useContext(NewsletterContext);
  if (!ctx) {
    throw new Error('useNewsletterContext cannot be used outside of NewsLetterContext.Provider');
  }
  return ctx;
};

export default NewsletterSubscription;
