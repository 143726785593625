import { Box, Heading, SystemStyleObject } from '@chakra-ui/react';
import { cmsRichTextRules } from 'inspiretec-booking-journey-ui/constants/sanitize';
import DOMPurify from 'isomorphic-dompurify';
import React from 'react';
import { useNewsletterContext } from '..';

const NewsletterSubscriptionIntro = () => {
  const { headingText, primaryText, image } = useNewsletterContext();

  const containerStylesForNoImage: SystemStyleObject = {
    display: 'flex',
    alignItems: {
      base: 'flex-start',
      '2xl': !image ? 'flex-end' : 'flex-start',
    },
    gap: 4,
    flexDir: {
      base: 'column',
      '2xl': !image ? 'row' : 'column',
    },
  };

  const headingCustomStyles: SystemStyleObject = !image
    ? {
        mb: 0,
        lineHeight: 1,
        fontSize: '3xl',
      }
    : {
        mb: 4,
        fontSize: '2xl',
      };

  return (
    <Box sx={!image ? containerStylesForNoImage : {}} className="NewsletterSubscriptionIntro">
      <Heading as="h2" fontSize="lg" sx={headingCustomStyles}>
        {headingText}
      </Heading>
      <Box
        className="RichText"
        mt={0}
        lineHeight={!image ? ['unset', 'unset', 1] : ''}
        flex={1}
        flexBasis="40%"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(primaryText, cmsRichTextRules) }}
      />
    </Box>
  );
};

export default NewsletterSubscriptionIntro;
