import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

interface CarouselPaginationDotsProps extends BoxProps {
  totalItems: number;
  currentIndex: number;
  navigateToSlide: (index: number) => void;
}

const CarouselPaginationDots = ({
  totalItems,
  currentIndex,
  navigateToSlide,
  ...props
}: CarouselPaginationDotsProps) => (
  <Box justifyContent="center" alignItems="center" className="PaginationDotsContainer" {...props}>
    {Array.from({ length: totalItems }).map((_, index) => (
      <Box
        key={index}
        w="10px"
        h="10px"
        mx="4px" // half of 8px for gap on both sides
        borderRadius="50%"
        bg="currentColor"
        opacity={index === currentIndex ? 1 : 0.5}
        cursor="pointer"
        onClick={() => navigateToSlide(index)}
        className="PaginationDotItem"
      />
    ))}
  </Box>
);

export default CarouselPaginationDots;
