export default (price: number) => {
  if (typeof price !== 'number' || (!price && price !== 0)) return null;

  let validPrice = price.toString();
  if (price !== Math.floor(price)) validPrice = price.toFixed(2);
  let formattedPrice = validPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const priceArray = formattedPrice.split('.');
  if (priceArray.length > 1 && priceArray[1].length === 1) formattedPrice += '0';

  return formattedPrice;
};
