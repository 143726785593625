'use client';
import React from 'react';
import { IVideo } from '../../types';
import Module from '../Module';
import VideoBase from '../VideoBase';

interface IVideoPlayerProps extends IVideo {
  onPlay?: () => void;
  onPause?: () => void;
  onEnd?: () => void;
}

const Video = ({ videoLink, videoPlaceholderImage, onPlay, onPause, onEnd }: IVideoPlayerProps) => (
  <Module className="Video">
    <VideoBase
      videoLink={videoLink}
      videoPlaceholderImage={videoPlaceholderImage}
      onPlay={onPlay}
      onPause={onPause}
      onEnd={onEnd}
    />
  </Module>
);

export default Video;
