import { chakra, Image as Img, SystemStyleObject } from '@chakra-ui/react';
import { BaseBreakpointConfig } from '@chakra-ui/theme-tools';
import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { MEDIA_BASE_PATH } from '../../constants/routes';
import theme from '../../theme';
import { Image } from '../../types';
import { sortBreakpoints } from '../../utils/chakra-ui';

type PictureSize = (string | number)[];
export type PictureSizes = PictureSize[];
type PictureOptions = {
  image: Image;
  eager?: boolean;
  sizes: PictureSizes;
};
type PictureProps = {
  options: PictureOptions;
  sx?: SystemStyleObject;
  className?: string;
};

enum ImageUnits {
  ViewWidth = 'vw',
  Pixels = 'px',
}

const getImageAttributes = (
  key: string,
  index: number,
  sortedBreakpoints: BaseBreakpointConfig,
  pictureSizes: PictureSizes,
  image: Image
) => {
  const keys = Object.keys(sortedBreakpoints);
  const nextKey = index + 1 < keys.length ? keys[index + 1] : null;
  const nextBreakpointValue = nextKey
    ? parseInt(sortedBreakpoints[nextKey] as string, 10)
    : Math.round(parseInt(sortedBreakpoints[key] as string, 10) * 1.3333);
  const isMobile = key === 'sm';
  const breakpointSize = sortedBreakpoints[key];
  const maxImageSize = nextBreakpointValue;
  const [aspectRatio, responsiveWidth] = pictureSizes[index];

  let sizeUnit = ImageUnits.ViewWidth;
  if (typeof responsiveWidth === 'string') {
    const match = responsiveWidth.match(/[a-zA-Z%]+/);
    if (match?.length) {
      sizeUnit = match[0] as ImageUnits;
    }
  }
  const sizeInt = parseInt(String(responsiveWidth));
  let widthOfImageInPixels = sizeInt;
  if (sizeUnit === ImageUnits.ViewWidth) {
    widthOfImageInPixels = Math.round((sizeInt / 100) * maxImageSize);
  }

  const aspectRatioHeight = Math.ceil(widthOfImageInPixels / parseFloat(String(aspectRatio)));

  const url = `${MEDIA_BASE_PATH}${
    image?.url
  }?rmode=crop&width=${widthOfImageInPixels}&height=${aspectRatioHeight}&quality=${65}&rxy=${
    image?.focalPointLeft
  },${image?.focalPointTop}`;
  const url2x =
    `${MEDIA_BASE_PATH}${image?.url}?rmode=crop&width=${widthOfImageInPixels * 2}&height=${
      aspectRatioHeight * 2
    }&quality=${80}&rxy=${image?.focalPointLeft},${image?.focalPointTop}` ?? '';

  return {
    url,
    srcSet: `${url} ${widthOfImageInPixels}w${
      isMobile ? '' : `, ${url2x} ${widthOfImageInPixels * 2}w`
    }`,
    media: `(min-width: ${breakpointSize})`,
    sizes: `${sizeInt}${sizeUnit}`,
  };
};

const getSource = (
  key: string,
  index: number,
  breakpoints: BaseBreakpointConfig,
  pictureSizes: PictureSizes,
  image: Image
) => {
  const attributes = getImageAttributes(key, index, breakpoints, pictureSizes, image);
  return <source key={key} {...attributes} />;
};

const parsePictureAspectRatio = (pictureSizes: PictureSizes) => {
  return {
    base: `${pictureSizes[0][0]}`,
    sm: `${pictureSizes[0][0]}`,
    md: `${pictureSizes[1][0]}`,
    lg: `${pictureSizes[2][0]}`,
    xl: `${pictureSizes[3][0]}`,
    '2xl': `${pictureSizes[4][0]}`,
  };
};

const Picture = ({ className, options, sx }: PictureProps) => {
  const [hasBeenInView, setHasBeenInView] = useState(options.eager);
  const sortedBreakpoints = sortBreakpoints(theme.breakpoints);
  delete sortedBreakpoints.base;
  const breakpointsKeys = Object.keys(sortedBreakpoints);
  /**
   * 0: "base"
   * 1: "sm"
   * 2: "md"
   * 3: "lg"
   * 4: "xl"
   * 5: "2xl"
   */
  const pictureSizes = [...options.sizes];
  const desktopBreakpointIndex = breakpointsKeys.findIndex((key) => key === 'xl');
  const { url: defaultUrl } = getImageAttributes(
    'xl',
    desktopBreakpointIndex,
    sortedBreakpoints,
    pictureSizes,
    options.image
  );

  const onInView = (inView: boolean) => {
    if (!hasBeenInView && inView) {
      setHasBeenInView(true);
    }
  };

  return (
    <>
      <InView
        as="picture"
        onChange={onInView}
        triggerOnce
        initialInView={options.eager}
        rootMargin="25% 0px 25% 0px"
      >
        {hasBeenInView ? (
          <>
            {breakpointsKeys
              .map((key, breakpointIndex) =>
                getSource(key, breakpointIndex, sortedBreakpoints, pictureSizes, options.image)
              )
              .reverse()}
            <Img
              className={className}
              src={defaultUrl}
              alt={options.image?.alt}
              style={{ objectFit: 'cover' }}
              loading={options.eager ? 'eager' : 'lazy'}
              width="100%" // the sizes that can be pumped into here can be too large and clipped in a flex
              sx={{
                aspectRatio: parsePictureAspectRatio(pictureSizes),
                ...sx,
              }}
            />
          </>
        ) : (
          <Img
            className={className}
            as="div"
            alt={options.image?.alt}
            style={{ objectFit: 'cover' }}
            width="100%" // the sizes that can be pumped into here can be too large and clipped in a flex
            bg="gray.100"
            sx={{
              aspectRatio: parsePictureAspectRatio(pictureSizes),
              ...sx,
            }}
          />
        )}
      </InView>
    </>
  );
};

Picture.displayName = 'Picture';

export default chakra(Picture);
