import { Box, Text, useBreakpoint, useMultiStyleConfig, VisuallyHidden } from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { default as Flickity, default as FlickityReactComponent } from 'react-flickity-component';
import {
  HeroBannerCarouselItem,
  ICategoryCtaCarouselItem,
  IGenericCtaCarouselItem,
  IImageCtaItem,
  Image,
} from '../../types';
import CarouselPaginationButton from './CarouselPaginationButton';
import CarouselPaginationDots from './CarouselPaginationDots';
import { CarouselVariant, ComponentRenderState, ItemsRenderState } from './constants';
import { getImageObj } from './helpers';

export interface CarouselBaseProps {
  items:
    | Image[]
    | IImageCtaItem[]
    | HeroBannerCarouselItem[]
    | IGenericCtaCarouselItem[]
    | ICategoryCtaCarouselItem[];
  RenderComponent: FunctionComponent<any>;
  isFullWidth?: boolean;
  carouselVariant: CarouselVariant;
  paginationButtonVariant: 'chevron' | 'arrowLarge' | 'arrowSmall';
}

const renderItems = (
  items: (
    | Image
    | IImageCtaItem
    | HeroBannerCarouselItem
    | IGenericCtaCarouselItem
    | ICategoryCtaCarouselItem
  )[],
  isFullWidth: boolean,
  RenderComponent: FunctionComponent<any>,
  itemsRenderState: ItemsRenderState,
  currentSlide: number,
  variant?: string
) => {
  const renderedItems = items.filter(
    (_, index) => index === 0 || (index > 0 && itemsRenderState !== ItemsRenderState.RENDER_FIRST)
  );
  return renderedItems.map((item, index) => (
    <RenderComponent
      key={getImageObj(item)?.url + index}
      item={item}
      isFullWidth={isFullWidth}
      isActiveSlide={currentSlide === index}
      index={index}
      numberOfItems={items.length}
      variant={variant}
    />
  ));
};

const CarouselBase = ({
  items,
  RenderComponent,
  isFullWidth = false,
  carouselVariant,
  paginationButtonVariant,
}: CarouselBaseProps) => {
  const breakpoint = useBreakpoint();
  const [renderState, setRenderState] = useState(ComponentRenderState.SSR_UNMOUNTED);
  const flickity = useRef<FlickityReactComponent>();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [carouselScreenReaderCountMessage, setCarouselScreenReaderCountMessage] = useState('');
  const [slidesCount, setSlidesCount] = useState(0);

  const updateFlickityUi = useCallback(
    debounce(() => {
      if (flickity.current && flickity.current.slides) {
        setSlidesCount(flickity.current.slides.length);
        flickity.current.resize();
      }
    }, 200),
    []
  );

  const carouselBaseTheme = useMultiStyleConfig('CarouselBase', {
    baseStyles: true,
    variant: carouselVariant,
  });

  const flickityRef = (ref: FlickityReactComponent) => {
    flickity.current = ref;
  };

  const onFlickityReady = () => {
    setRenderState(ComponentRenderState.FLICKITY_READY);
    updateFlickityUi();
  };

  const onPrevious = () => {
    flickity.current?.previous();
  };

  const onNext = () => {
    flickity.current?.next();
  };

  const navigateToSlide = (index: number) => {
    flickity.current?.select(index);
  };

  const handleSlideChange = (index: number) => {
    if (flickity.current) {
      setCurrentSlide(index);

      const selectedCellsIndexes: Array<number> = (flickity.current as any).selectedCells.map(
        (cell: number) => (flickity.current?.cells as Array<ReactNode>)?.indexOf(cell)
      );

      const altTexts = selectedCellsIndexes.map((cellIndex) => getImageObj(items[cellIndex]).alt);

      setCarouselScreenReaderCountMessage(
        `${altTexts.length ? altTexts.join(', ') : 'Carousel image(s)'} slide ${
          index + 1
        } of ${slidesCount}`
      );
    }
  };

  useEffect(() => {
    updateFlickityUi();
  }, [breakpoint]);

  useEffect(() => {
    setRenderState(ComponentRenderState.CLIENT_MOUNTED);
  }, []);

  const flickityOptions: any = {
    // using custom buttons and page
    prevNextButtons: false,
    pageDots: false,
    draggable: '>1',
    cellAlign: 'left',
    contain: true,
    wrapAround: false,
    groupCells: true,
    on: {
      ready: onFlickityReady,
      change: handleSlideChange,
    },
  };

  // We hide flickity before being ready behind single item render
  const hideFlickityWrapperSx =
    renderState === ComponentRenderState.CLIENT_MOUNTED
      ? {
          position: 'absolute',
          opacity: 0,
          width: '100%',
        }
      : {};

  return (
    <Box
      position="relative"
      overflow="hidden"
      margin="auto"
      maxWidth={isFullWidth ? '100%' : '1440px'}
      borderRadius={isFullWidth ? 0 : '20px'}
      sx={{
        '.flickity-enabled:focus-visible': {
          outline: 0,
          // TODO: This isn't a complete border due to overflow hidden on parent that is needed for Flickity
          // but display position on :after  causes flickity not to drag.
          // This method creates a thin focus ring to all carousels - currently causing issues.
          // boxShadow: 'inset 0 0 0 8px rgba(243, 169, 0, 1)',
        },
        '.flickity-enabled': {
          position: 'relative',
        },
        ...carouselBaseTheme.wrapper,
      }}
    >
      {/* Begin Rendering Flickity when Client Mounted */}
      {renderState > ComponentRenderState.SSR_UNMOUNTED && (
        <Box sx={hideFlickityWrapperSx}>
          <Flickity flickityRef={flickityRef} options={flickityOptions}>
            {renderItems(
              items,
              isFullWidth,
              RenderComponent,
              ItemsRenderState.SHOW_ALL,
              currentSlide,
              carouselVariant
            )}
          </Flickity>
        </Box>
      )}
      {/* Render First Item on top of Flickity until Flickity is ready */}
      {renderState < ComponentRenderState.FLICKITY_READY &&
        renderItems(
          items,
          isFullWidth,
          RenderComponent,
          ItemsRenderState.RENDER_FIRST,
          currentSlide,
          carouselVariant
        )}
      {/* Show Flickity controls only when ready */}
      {renderState === ComponentRenderState.FLICKITY_READY && slidesCount > 1 && (
        <Box sx={carouselBaseTheme.buttonsWrapper} className="CarouselPaginationWrapper">
          <CarouselPaginationButton
            onClick={onPrevious}
            disabled={currentSlide === 0}
            directionText="Previous"
            variant={paginationButtonVariant}
            sx={carouselBaseTheme.leftButton}
          />
          <Box
            sx={{ ...carouselBaseTheme.dotsWrapper, marginBottom: '0px' }}
            className="DotsWrapper"
          >
            <CarouselPaginationDots
              totalItems={slidesCount}
              currentIndex={currentSlide}
              navigateToSlide={navigateToSlide}
              sx={carouselBaseTheme.dots}
            />
          </Box>
          <Text className="CarouselBaseText" my={0} color="white" sx={carouselBaseTheme.text}>
            {' '}
            {currentSlide + 1} / {slidesCount}
          </Text>
          <CarouselPaginationButton
            onClick={onNext}
            disabled={currentSlide === slidesCount - 1}
            directionText="Next"
            variant={paginationButtonVariant}
            sx={carouselBaseTheme.rightButton}
          />
        </Box>
      )}
      {/* Reads out to screen reader image on carousel. */}
      <VisuallyHidden aria-live="polite">{carouselScreenReaderCountMessage}</VisuallyHidden>
    </Box>
  );
};

CarouselBase.displayName = 'CarouselBase';

export default CarouselBase;
