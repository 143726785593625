import { Button, Flex, Heading } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';
import { ICategoryCtaCarouselItem, Link as ILink } from '../../types';
import CarouselBase from '../CarouselBase';
import { CarouselVariant } from '../CarouselBase/constants';
import Module, { ModuleType } from '../Module';
import CategoryCtaCarouselItem from './CategoryCtaCarouselItem';

export interface categoryCtaCarouselProps {
  categoryCtaCarouselItems: ICategoryCtaCarouselItem[];
  categoryCtaCarouselTitle?: string;
  categoryCtaCarouselLink?: ILink;
}

/**
 * ## Category CTA Carousel
 * - A carousel which changes dependant on the viewport and number of items
 * - Each slide has an image, heading and a link
 * - The carousel can optionally have a title and a button link above it
 */
const CategoryCtaCarousel = ({
  categoryCtaCarouselItems,
  categoryCtaCarouselTitle,
  categoryCtaCarouselLink,
}: categoryCtaCarouselProps) => {
  // DELETE AFTER TESTING!
  const [causeError, setCauseError] = React.useState(false);
  const errorRef = React.useRef(null);

  if (causeError) {
    throw new Error('Triggered error in CategoryCtaCarousel');
  }

  React.useEffect(() => {
    //@ts-expect-error // testing
    window.triggerCategoryCtaCarouselError = () => {
      if (errorRef.current) {
        setCauseError(true);
      }
    };

    return () => {
      //@ts-expect-error // testing
      window.triggerCategoryCtaCarouselError = undefined;
    };
  }, []);

  return (
    <Module className="CategoryCtaCarousel" type={ModuleType.DEFAULT}>
      <Flex
        justifyContent="space-between"
        mb={categoryCtaCarouselTitle || categoryCtaCarouselLink ? 8 : 0}
        align="center"
        ref={errorRef}
      >
        {categoryCtaCarouselTitle && <Heading size="3xl">{categoryCtaCarouselTitle}</Heading>}
        {/* Desktop CTA button */}
        {categoryCtaCarouselLink && (
          <Link
            href={categoryCtaCarouselLink?.url}
            as={categoryCtaCarouselLink?.url}
            passHref
            legacyBehavior
          >
            <Button
              as="a"
              variant="outline"
              ml="auto"
              tabIndex={-1}
              display={{ base: 'none', md: 'inline-flex' }}
              target={categoryCtaCarouselLink?.isNewWindow ? '_blank' : '_self'}
              rel={categoryCtaCarouselLink?.isNewWindow ? 'noopener noreferrer' : undefined}
            >
              {categoryCtaCarouselLink.title}
            </Button>
          </Link>
        )}
      </Flex>
      <CarouselBase
        items={categoryCtaCarouselItems}
        RenderComponent={CategoryCtaCarouselItem}
        carouselVariant={CarouselVariant.CATEGORY_CTA}
        paginationButtonVariant="chevron"
      />
      {/* Mobile CTA button */}
      {categoryCtaCarouselLink && (
        <Link
          href={categoryCtaCarouselLink?.url}
          as={categoryCtaCarouselLink?.url}
          passHref
          legacyBehavior
        >
          <Button
            as="a"
            variant="outline"
            tabIndex={-1}
            w="full"
            display={{ base: 'inline-flex', md: 'none' }}
            mt={5}
            target={categoryCtaCarouselLink?.isNewWindow ? '_blank' : '_self'}
            rel={categoryCtaCarouselLink?.isNewWindow ? 'noopener noreferrer' : undefined}
          >
            {categoryCtaCarouselLink.title}
          </Button>
        </Link>
      )}
    </Module>
  );
};

CategoryCtaCarousel.displayName = 'CategoryCtaCarousel';

export default CategoryCtaCarousel;
