import { Box, Flex, useMultiStyleConfig, useTheme } from '@chakra-ui/react';
import classNames from 'classnames';
import React from 'react';
import {
  ContentPlaceholder as IContentPlaceholder,
  HeroBannerItem as HeroBannerItemProps,
} from '../../types';
import ContentPlaceholder from '../ContentPlaceholder';
import GradientOverlay from '../HeroBannerCarousel/GradientOverlay';
import Module, { MarginType, ModuleType } from '../Module';
import Picture from '../Picture';
import StandardContainer from '../StandardContainer';
import HeroContent from './HeroContent';

export interface HeroBannerProps extends HeroBannerItemProps {
  heroBannerPlaceholder: IContentPlaceholder[];
  heroPlaceholderIsAboveHeroOnDesktop?: boolean;
  hasTransparentHeader?: boolean;
}

const HeroBanner = ({
  hasTransparentHeader,
  heroPlaceholderIsAboveHeroOnDesktop = false,
  heroBannerPlaceholder,
  heroBannerImage,
  heroBannerTitle,
  heroBannerLink,
}: HeroBannerProps) => {
  const { globals } = useTheme();
  const theme = useMultiStyleConfig('HeroBanner', { baseStyles: true });
  const hasPlaceholder = heroBannerPlaceholder?.length > 0;

  // Conditional display of search form based on hero config, combination of render logic and
  // styles to ensure SSR render and accessible read order

  // Display for showing search above hero only on desktop (xl)
  const displaySearchFormAboveHeroOnDesktop = {
    base: 'none',
    ...(heroPlaceholderIsAboveHeroOnDesktop
      ? {
          xl: 'block',
        }
      : {}),
  };

  // Display for showing search over hero only on desktop (xl)
  const displaySearchFormOverHeroOnDesktop = {
    base: 'none',
    lg: 'block',
    ...(heroPlaceholderIsAboveHeroOnDesktop
      ? {
          xl: 'none',
        }
      : { xl: 'block' }),
  };

  // Display for showing search below hero below desktop (xl)
  const displaySearchFormBelowDesktop = { base: 'block', lg: 'none' };

  const heroBannerClasses = classNames('HeroBanner', {
    'HeroBanner-placeholderAboveHero': heroPlaceholderIsAboveHeroOnDesktop,
  });

  return (
    <Module
      className={heroBannerClasses}
      type={ModuleType.FULL_WIDTH_NO_PADDING}
      marginType={MarginType.SNUG}
    >
      <Box w="100%" position="relative">
        {/* Search Above Hero when above the desktop lg breakpoint */}
        {!hasTransparentHeader && hasPlaceholder && heroPlaceholderIsAboveHeroOnDesktop && (
          <Box bg="white">
            <StandardContainer display={displaySearchFormAboveHeroOnDesktop}>
              <Box mx={-8}>
                <ContentPlaceholder components={heroBannerPlaceholder} isModule={false} />
              </Box>
            </StandardContainer>
          </Box>
        )}
        <Box position="relative">
          <Box>
            <Picture
              position={{ base: 'relative', lg: 'absolute', xl: 'relative' }} // unique layout
              height={{
                base: '100%',
                lg: hasPlaceholder ? 'calc(100% - 116px)' : 'full',
                xl: 'full',
              }}
              options={{
                image: heroBannerImage,
                eager: true,
                sizes: [
                  [1.13, 100], // base
                  [2.3, 100], // md
                  [heroPlaceholderIsAboveHeroOnDesktop ? 2.36 : 2, 100], // lg
                  [heroPlaceholderIsAboveHeroOnDesktop && hasTransparentHeader ? 2.2 : 2.76, 100], // xl
                  [heroPlaceholderIsAboveHeroOnDesktop && hasTransparentHeader ? 2.36 : 2.76, 100], // 2xl 1920px +
                ],
              }}
            />
            <GradientOverlay zIndex="1" height={{ base: '100%', md: '70%' }} />
          </Box>

          <Flex
            className="HeroContainer"
            position={{ base: 'absolute', lg: 'relative', xl: 'absolute' }}
            width="100%"
            height="100%"
            top="0"
            left="0"
            direction="column"
            justifyContent={{
              base: 'flex-end',
              sm: 'flex-start',
              lg: 'flex-start',
              xl:
                hasTransparentHeader && hasPlaceholder && heroPlaceholderIsAboveHeroOnDesktop
                  ? 'flex-start'
                  : 'center',
            }}
            pt={hasTransparentHeader ? globals.searchHeight : 0}
          >
            {hasTransparentHeader && hasPlaceholder && heroPlaceholderIsAboveHeroOnDesktop && (
              <Box bg="white">
                <StandardContainer display={displaySearchFormAboveHeroOnDesktop}>
                  <Box mx={-8}>
                    <ContentPlaceholder components={heroBannerPlaceholder} isModule={false} />
                  </Box>
                </StandardContainer>
              </Box>
            )}

            {/* Search Over Hero when above the desktop lg breakpoint */}
            <HeroContent
              link={heroBannerLink}
              title={heroBannerTitle}
              theme={theme}
              heroPlaceholderIsAboveHeroOnDesktop={heroPlaceholderIsAboveHeroOnDesktop}
            >
              {hasPlaceholder && (
                <Box
                  className="HeroBanner_contentPlaceholder"
                  display={displaySearchFormOverHeroOnDesktop}
                  _first={{
                    marginTop: { base: 0, lg: '350px', xl: 0 }, // typical height of the search form allowing the image to take up 50% of the component
                  }}
                >
                  <ContentPlaceholder components={heroBannerPlaceholder} isModule={false} />
                </Box>
              )}
            </HeroContent>
          </Flex>
        </Box>
      </Box>

      {/* Search On Mobile */}
      {hasPlaceholder && (
        <Box display={displaySearchFormBelowDesktop}>
          <ContentPlaceholder components={heroBannerPlaceholder} isModule={false} />
        </Box>
      )}
    </Module>
  );
};

HeroBanner.displayName = 'HeroBanner';

export default HeroBanner;
