import { Box, Heading, HStack, Skeleton, SkeletonText } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';
import Module, { ModuleType } from '../Module';

const roughHeightOfByWay = '309px';

const LoadingField = () => (
  <Box borderRadius="md" px="3" py="4" borderWidth="1px" flexBasis="25%">
    <SkeletonText mt="0" noOfLines={1} spacing="4" skeletonHeight="2" width="25%" />
  </Box>
);

const LoadingItem = () => (
  <Box padding="0" boxShadow="md">
    <Skeleton width="224px" height="126" />
    <Box padding="3">
      <SkeletonText mt="2" noOfLines={3} spacing="4" skeletonHeight="2" />
    </Box>
  </Box>
);

export const ByWayLoading = () => (
  <Box h={roughHeightOfByWay}>
    <HStack gap="2">
      <LoadingField />
      <LoadingField />
      <LoadingField />
      <LoadingField />
    </HStack>
    <Box overflowX="scroll" mt="1rem">
      <HStack gap="2" mb="1rem">
        <LoadingItem />
        <LoadingItem />
        <LoadingItem />
        <LoadingItem />
        <LoadingItem />
        <LoadingItem />
      </HStack>
    </Box>
  </Box>
);

const DynamicByWay = dynamic(() => import('./ByWay'), {
  loading: ByWayLoading,
});

export interface ByWayProps {
  byWayTitle?: string;
  byWayPartnerName: string;
  byWaySearchFilter: string;
  byWayDurationFilter: string;
  byWayFeatureFilter: string;
}

const ByWay = ({
  byWayTitle,
  byWayPartnerName,
  byWaySearchFilter,
  byWayDurationFilter,
  byWayFeatureFilter,
}: ByWayProps) => {
  const [hasBeenInView, setHasBeenInView] = useState(false);

  const onInView = (inView: boolean) => {
    if (!hasBeenInView && inView) {
      setHasBeenInView(true);
    }
  };

  return (
    <Module className="ByWay" type={ModuleType.DEFAULT}>
      {byWayTitle && (
        <Heading as="h2" size="3xl" mb={12}>
          {byWayTitle}
        </Heading>
      )}
      <InView onChange={onInView} triggerOnce rootMargin="10% 0px 10% 0px">
        {hasBeenInView ? (
          <DynamicByWay
            byWayPartnerName={byWayPartnerName}
            byWaySearchFilter={byWaySearchFilter}
            byWayDurationFilter={byWayDurationFilter}
            byWayFeatureFilter={byWayFeatureFilter}
          />
        ) : (
          <ByWayLoading />
        )}
      </InView>
    </Module>
  );
};

ByWay.displayName = 'ByWay';

export default ByWay;
