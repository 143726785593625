import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { REACT_QUERY_CACHE_TIME, REACT_QUERY_STALE_TIME } from '../../constants/query-cache-time';
import { MILLISECONDS_PER_SECOND } from '../../constants/time';
import { axiosGet, getContentApi } from '../../utils/api';

interface FormBody {
  formHtml: string;
  id: string;
  scriptsHtml: string;
}

const getFormFromApi = (id: string) => async () => {
  const url = `${getContentApi()}/form/${id}/?theme=Product`;

  const response = (await axiosGet(url)) as AxiosResponse<FormBody>;

  if (!response.data) {
    // we can reject and incur an error as this should only be called on the client side
    return Promise.reject();
  }

  return Promise.resolve({ ...response?.data });
};

const useForm = () => {
  const [formId, setFormId] = useState<string | undefined>(undefined);

  const onError = (error: AxiosError) => {
    console.error(error);
  };

  const getFormBody = useCallback(
    (id: string) => {
      setFormId(id);
    },
    [setFormId]
  );

  const options: UseQueryOptions<FormBody, AxiosError> = {
    queryKey: ['form', formId],
    queryFn: getFormFromApi(formId || ''),
    staleTime: MILLISECONDS_PER_SECOND * REACT_QUERY_STALE_TIME,
    cacheTime: MILLISECONDS_PER_SECOND * REACT_QUERY_CACHE_TIME,
    onError,
    enabled: !!formId,
    retry: 1,
  };

  const response = useQuery(options);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: formBody, refetch, remove, ...responseProps } = response;

  return {
    getFormBody,
    formBody,
    ...responseProps,
  };
};

export default useForm;
