import React, { useMemo } from 'react';
import { ContentPlaceholder as ContentPlaceholderComponents } from '../../types';
import DynamicComponent from '../DynamicComponent';

interface ContentPlaceholdersProps {
  components: ContentPlaceholderComponents[];
  isModule?: boolean;
  hasTransparentHeader?: boolean;
}

const ContentPlaceholder = ({
  components,
  isModule = true,
  hasTransparentHeader = false,
}: ContentPlaceholdersProps) => {
  const componentsIdsDependency = components.map(({ id }) => id).join(',');
  // If we use map within the render function, every time the ContentPlaceholder is rendered
  // it will create a new array and automatically re-render all the components unnecessarily.
  const componentsMemoised = useMemo(() => {
    return components?.map((content: any) => (
      <DynamicComponent
        isModule={isModule}
        hasTransparentHeader={hasTransparentHeader}
        key={content.id}
        {...content}
      />
    ));
  }, [componentsIdsDependency]);

  return <>{componentsMemoised}</>;
};

ContentPlaceholder.displayName = 'ContentPlaceholder';

export default ContentPlaceholder;
