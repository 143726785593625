import React from 'react';
import { ContentPlaceholder as IContentPlaceholder, HeroBannerCarouselItem } from '../../types';
import CarouselBase from '../CarouselBase';
import { CarouselVariant } from '../CarouselBase/constants';
import { ModuleContentType } from '../constants';
import ContentPlaceholder from '../ContentPlaceholder';
import Module, { MarginType, ModuleType } from '../Module';
import StandardContainer from '../StandardContainer';
import HeroCarouselItem from './HeroCarouselItem';

export interface HeroBannerCarouselProps {
  heroBannerCarouselItems: HeroBannerCarouselItem[];
  heroBannerCarouselIsShort?: boolean;
  heroBannerCarouselPlaceholder?: IContentPlaceholder[];
}

const HeroBannerCarousel = ({
  heroBannerCarouselItems,
  heroBannerCarouselPlaceholder,
  heroBannerCarouselIsShort,
}: HeroBannerCarouselProps) => {
  const renderBasedOnSearchAndShortVariant = () => {
    // do not render placeholder if search is used with short variant
    if (!heroBannerCarouselPlaceholder) return false;
    const bookingJourneySearchIsUsed = heroBannerCarouselPlaceholder.some(
      (item) => item.contentType === ModuleContentType.BOOKING_JOURNEY_SEARCH
    );
    const searchUsedWithShortBanner = heroBannerCarouselIsShort && bookingJourneySearchIsUsed;
    return !searchUsedWithShortBanner;
  };

  return (
    <Module
      className="HeroBannerCarousel"
      type={ModuleType.FULL_WIDTH_NO_PADDING}
      marginType={MarginType.SNUG}
    >
      <CarouselBase
        items={heroBannerCarouselItems}
        RenderComponent={(props) => (
          <HeroCarouselItem {...props} heroBannerCarouselIsShort={heroBannerCarouselIsShort} />
        )}
        isFullWidth
        carouselVariant={
          heroBannerCarouselIsShort ? CarouselVariant.HERO_SHORT : CarouselVariant.HERO_FULL
        }
        paginationButtonVariant="arrowLarge"
      />
      {heroBannerCarouselPlaceholder &&
        heroBannerCarouselPlaceholder?.length > 0 &&
        renderBasedOnSearchAndShortVariant() && (
          <StandardContainer
            zIndex="var(--chakra-zIndices-auto)"
            position="relative"
            className="ProductSearchContainer"
          >
            <ContentPlaceholder components={heroBannerCarouselPlaceholder} isModule={false} />
          </StandardContainer>
        )}
    </Module>
  );
};

HeroBannerCarousel.displayName = 'HeroBannerCarousel';

export default HeroBannerCarousel;
