import { Box } from '@chakra-ui/react';
import React from 'react';
import { useNewsletterContext } from '..';
import Picture from '../../Picture';

const NewsletterSubscriptionImage = () => {
  const { image } = useNewsletterContext();
  if (!image) return <></>;
  return (
    <Box>
      <Picture
        options={{
          image,
          sizes: [
            [1.77, 100], // >= base / sm - 16:9 aspect ratio
            [1, 100], // >= md 768px - square 1:1 aspect ratio
            [2.2, 100], // >= lg 1024px - widescreen aspect ratio
            [2.2, 100], // >= xl 1440px
            [2.2, 100], // >=2xl 1920px
          ],
        }}
        sx={{
          borderLeftRadius: {
            base: 0,
            md: 10,
          },
          borderTopRightRadius: {
            base: 10,
            md: 0,
          },
          borderTopLeftRadius: 10,
          height: '100%',
        }}
      />
    </Box>
  );
};

export default NewsletterSubscriptionImage;
