import { Box, Button, Input } from '@chakra-ui/react';
import { StatusCodes } from 'http-status-codes';
import getConfig from 'next/config';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNewsletterContext } from '..';
import useBrochurewareDictionary from '../../../api/Dictionary/useBrochurewareDictionary';
import useNewsletterSignup from '../../../queries/content/useNewsletterSignup';
import { initRecaptchaV3 } from '../../../utils/initRecaptcha';
import StatusChip, { ChipStatusType, StatusChipValues } from '../../StatusChip/StatusChip';

const NewsletterSubscriptionForm = () => {
  const { publicRuntimeConfig } = getConfig();
  const { image, submitButtonText } = useNewsletterContext();

  const [formEnabled, setFormEnabled] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const [emailAddress, setEmailAddress] = useState('');
  const emailAddressInput = useRef<HTMLInputElement | null>(null);

  const { labels, validation } = useBrochurewareDictionary('NewsletterSubscription');

  useEffect(() => {
    // Adds the recaptcha script to the page so we can
    // use the grecaptcha global
    initRecaptchaV3((success) => {
      if (!success) return;
      setFormEnabled(true);
    });
  }, []);

  const [subscribe, { statusCode, isLoading }] = useNewsletterSignup();

  const postWasSuccessful = statusCode === StatusCodes.OK;

  const messages: { [key: number]: string } = {
    // Return code for a successful newsletter subscribe
    [StatusCodes.OK]: labels('subscribeSuccess'),
    // Return code for a malformed request
    [StatusCodes.BAD_REQUEST]: labels('subscribeWarning'),
    // Return code for failed recaptcha or post to CRM
    [StatusCodes.UNPROCESSABLE_ENTITY]: labels('subscribeWarning'),
  };

  const statuses: { [key: number]: StatusChipValues } = {
    [StatusCodes.OK]: ChipStatusType.SUCCESS,
    [StatusCodes.BAD_REQUEST]: ChipStatusType.WARNING,
    [StatusCodes.UNPROCESSABLE_ENTITY]: ChipStatusType.WARNING,
  };

  // Defaults for any events which are unexpected, e.g. a 500 error
  const defaultMessage = statusCode ? labels('subscribeWarning') : '';
  const defaultStatus = statusCode ? ChipStatusType.WARNING : '';

  const message = messages[statusCode] ?? defaultMessage;
  const subscribeStatus = statuses[statusCode] ?? defaultStatus;

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: email } = e.target;
    setEmailAddress(email);
  };

  const handleButtonClick = () => {
    setFormEnabled(false);
    setIsEmailValid(true);
    const isFormValid = emailAddressInput.current?.checkValidity();
    if (!isFormValid || !emailAddress) {
      setIsEmailValid(false);
      setFormEnabled(true);
      return;
    }
    grecaptcha.ready(() => {
      grecaptcha
        .execute(publicRuntimeConfig.recaptchaSiteKey, { action: 'newsletterSubscribe' })
        .then((recaptchaToken) => {
          subscribe({
            emailAddress,
            recaptchaToken,
          });
          setFormEnabled(true);
        });
    });
  };

  const customContainerStyles = !image
    ? {
        display: 'flex',
        alignItems: {
          base: 'left',
          md: 'center',
        },
        flexDirection: {
          base: 'column',
          md: 'row',
        },
        gap: {
          base: 1,
          md: 4,
        },
        mt: 2,
        mb: {
          base: 4,
          md: 0,
        },
      }
    : {
        mt: {
          base: statusCode ? 0 : 4,
          md: statusCode ? 0 : 5,
        },
        mb: {
          base: 4,
          md: 5,
        },
      };

  const customInputStyles = !image
    ? {
        my: 1,
        py: 0,
      }
    : {
        py: 3,
      };

  const isSubmitDisabled = !formEnabled || isLoading;

  return (
    <>
      {subscribeStatus && (
        <StatusChip
          status={subscribeStatus}
          sx={{
            mt: {
              base: 3,
              md: 2,
            },
            mb: {
              base: 2,
              md: !image ? 0 : 3,
            },
          }}
        >
          <StatusChip.Icon sx={{ boxSize: '28px' }} />
          <StatusChip.Message sx={{ fontWeight: 500, fontSize: 'sm' }}>
            {message}
          </StatusChip.Message>
        </StatusChip>
      )}
      {!postWasSuccessful && (
        <>
          <Box sx={customContainerStyles} className="NewsletterSubscriptionFormContainer">
            <Box width="100%">
              <Input
                value={emailAddress}
                onChange={handleEmailChange}
                ref={emailAddressInput}
                variant="flushed"
                placeholder={labels('emailPlaceholder')}
                size="lg"
                sx={customInputStyles}
                type="email"
              />
              {!isEmailValid && (
                <StatusChip status={ChipStatusType.ERROR} sx={{ mt: 2 }}>
                  <StatusChip.Icon sx={{ boxSize: '16px' }} />
                  <StatusChip.Message sx={{ fontSize: 'sm' }}>
                    {validation('invalidEmail')}
                  </StatusChip.Message>
                </StatusChip>
              )}
            </Box>
            <Button
              onClick={handleButtonClick}
              isDisabled={isSubmitDisabled}
              variant="outline"
              isLoading={isLoading}
              mb="auto"
              mt={!image ? 3 : 5}
              borderRadius="full"
              px={{
                base: 4,
                md: !image ? 6 : 4,
                lg: 4,
              }}
              width={{
                base: 'fit-content',
                md: 'auto',
              }}
            >
              {submitButtonText}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default NewsletterSubscriptionForm;
