import { Box } from '@chakra-ui/react';
import DOMPurify from 'isomorphic-dompurify';
import React from 'react';
import { useNewsletterContext } from '..';
import { cmsRichTextRules } from '../../../constants/sanitize';

const NewsletterSubscriptionOutro = () => {
  const { secondaryText } = useNewsletterContext();
  return (
    <Box
      className="RichText"
      mt={1}
      lineHeight="20px"
      fontSize="sm"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(secondaryText, cmsRichTextRules) }}
    />
  );
};

export default NewsletterSubscriptionOutro;
