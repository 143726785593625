import { Button, ButtonProps } from '@chakra-ui/react';
import React, { MouseEventHandler } from 'react';
import PaginationArrow from '../PaginationArrow';

interface CarouselPaginationButtonProps extends ButtonProps {
  disabled?: boolean;
  directionText?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  variant?: string;
}

const CarouselPaginationButton = ({
  disabled,
  directionText,
  onClick,
  variant,
  ...props
}: CarouselPaginationButtonProps) => {
  return (
    <Button
      isDisabled={disabled}
      aria-label={`${directionText} image button ${disabled ? 'disabled' : ''}`}
      height="auto"
      variant="unstyled"
      onClick={onClick}
      className="CarouselPaginationButton"
      minWidth="unset" // reset default min width 40px from chakra
      {...props}
    >
      <PaginationArrow variant={variant} />
    </Button>
  );
};

export default CarouselPaginationButton;
