import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Heading,
  useMultiStyleConfig,
  VStack,
} from '@chakra-ui/react';
import DOMPurify from 'isomorphic-dompurify';
import React from 'react';
import { cmsRichTextRules } from '../../constants/sanitize';
import { IExpandingContentList } from '../../types';
import Module, { ModuleType } from '../Module';

const ExpandingContentList = ({
  expandingContentListTitle,
  expandingContentListItems,
}: IExpandingContentList) => {
  const theme = useMultiStyleConfig('ExpandingContentList', { baseStyles: true });
  const itemTitleTag = expandingContentListTitle ? 'h3' : 'h2';

  return (
    <Module className="ExpandingContentList" type={ModuleType.CONTENT_WIDTH}>
      {expandingContentListTitle && (
        <Heading as="h2" size="3xl" sx={theme.listTitle} mb={9}>
          {expandingContentListTitle}
        </Heading>
      )}
      <Accordion defaultIndex={[]} allowMultiple>
        <VStack gap={{ base: '10px', md: 5 }} spacing={0}>
          {expandingContentListItems.map((expandingContentItem) => (
            <AccordionItem
              key={expandingContentItem.expandingContentItemTitle}
              w="full"
              border="1px solid var(--chakra-colors-chakra-border-color)"
              borderRadius={10}
              sx={{
                '&.chakra-accordion__item:last-of-type': {
                  borderBottomWidth: '1px', // Needed to override Chakra border-bottom
                },
              }}
            >
              <AccordionButton
                px={{ base: '15px', md: 5 }}
                pb={{ base: 3, md: 5 }}
                pt={{ base: 3, md: '19px' }}
                borderRadius={10}
                _expanded={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                _hover={{ background: 'gray.100' }}
              >
                <Box as="span" flex="1" textAlign="left">
                  <Heading as={itemTitleTag} size="2xl" sx={theme.itemTitle} mt={0}>
                    {expandingContentItem.expandingContentItemTitle}
                  </Heading>
                </Box>
                <AccordionIcon sx={theme.icon} />
              </AccordionButton>
              <AccordionPanel
                w="full"
                pt={6}
                pb={10}
                px={{ base: '15px', md: 5 }}
                borderTop="1px solid var(--chakra-colors-chakra-border-color)"
              >
                <VStack w="full" alignItems="flex-start" gap={5}>
                  <Box
                    className="RichText"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        expandingContentItem.expandingContentItemText,
                        cmsRichTextRules
                      ),
                    }}
                  />
                  {expandingContentItem.expandingContentItemLink?.url && (
                    <Button
                      as="a"
                      variant="outlineDark"
                      sx={{ marginTop: '0 !important' }} // Important needed to override Chakra margin.
                      href={expandingContentItem.expandingContentItemLink?.url}
                      target={
                        expandingContentItem.expandingContentItemLink?.isNewWindow
                          ? '_blank'
                          : '_self'
                      }
                      rel={
                        expandingContentItem.expandingContentItemLink?.isNewWindow
                          ? 'noopener' // prevents tabnabbing - security measure
                          : ''
                      }
                    >
                      {expandingContentItem.expandingContentItemLink?.title}
                    </Button>
                  )}
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </VStack>
      </Accordion>
    </Module>
  );
};

ExpandingContentList.displayName = 'ExpandingContentList';

export default ExpandingContentList;
