import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { Flex, SystemStyleObject, Text } from '@chakra-ui/react';
import React, { createContext, useContext } from 'react';

export const ChipStatusType = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
} as const;

type StatusChipKeys = keyof typeof ChipStatusType;
export type StatusChipValues = (typeof ChipStatusType)[StatusChipKeys];

interface IStatusChipContext {
  status: StatusChipValues;
}

// Currently used to pass the 'status' prop around so styling can be applied
// without developers having to duplicate props to every compound of the
// StatusChip component
const StatusChipContext = createContext<IStatusChipContext | null>(null);

export interface IStatusChipProps {
  status: StatusChipValues;
  children: React.ReactNode;
  sx?: SystemStyleObject;
}

const StatusChip = (props: IStatusChipProps) => {
  const { status, sx, children } = props;
  if (!status) return <></>;
  return (
    <StatusChipContext.Provider
      value={{
        status,
      }}
    >
      <Flex alignItems="center" gap={2} mt={1} mb={0} sx={sx}>
        {children && children}
      </Flex>
    </StatusChipContext.Provider>
  );
};

const StatusChipMessage = ({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SystemStyleObject;
}) => {
  const ctx = useContext(StatusChipContext);
  if (!ctx) return <></>;
  const { status } = ctx;
  const color = `${status}.500`;
  return (
    <Text mt={0} color={status === ChipStatusType.ERROR ? color : ''} sx={sx}>
      {children}
    </Text>
  );
};

const StatusChipIcon = ({ sx }: { sx?: SystemStyleObject }) => {
  const ctx = useContext(StatusChipContext);
  if (!ctx) return <></>;
  const { status } = ctx;
  const color = `${status}.500`;
  const iconProps = {
    color,
    sx,
  };
  if (status === ChipStatusType.SUCCESS) {
    return <CheckCircleIcon {...iconProps} />;
  }
  return <WarningIcon {...iconProps} />;
};

StatusChip.Icon = StatusChipIcon;
StatusChip.Message = StatusChipMessage;

export default StatusChip;
